import {
  Form,
  redirect,
  LoaderFunctionArgs,
  ActionFunctionArgs,
  useLoaderData,
  Link,
} from "react-router-dom";
import toast from "react-hot-toast";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import { Input, TextArea } from "../../components/inputs/Input";
import CustomSelect from "../../components/customSelect";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import {
  GET_LIST_ATTENDEES_BY_ORGANIZATION,
  GET_LIST_EVENTS_BY_ORGANIZATION,
} from "../../queries";
import { organizationStore } from "../../store/organization";
import { Attendee } from "../../types/attendee";
import { Event } from "../../types/event";
import { RFID } from "../../types/rfid";
import { UPDATE_RFID, UPDATE_RFID_WITHOUT_PIN } from "./mutations";
import { RFID_BY_ID_QUERY } from "./queries";
import { Badge } from "../../components/Badge";
import PriceInput from "../../components/inputs/PriceInput";
import { useEffect, useState } from "react";
import { userStore } from "../../store/user";

const client = createApolloClient();

const { getState } = organizationStore;
const { getState: getUserStore } = userStore;

interface TokenBalance {
  balance: number;
  redeemable_token_id: string;
  redeemable_token_name: string;
}

type TokenBalances = {
  [key: string]: TokenBalance;
};
export const editRfidAction = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const body = await request.formData();
  const cashBalanceString = body.get("cash_balance") as string;
  const cashBalance = parseFloat(
    cashBalanceString.length ? cashBalanceString : "0"
  );
  const promoBalanceString = body.get("promo_balance") as string;
  const promoBalance = parseFloat(
    promoBalanceString.length ? promoBalanceString : "0"
  );
  const updatedTokenBalances = JSON.parse(body.get("token_balances") as string);
  const user = getUserStore().user;

  if (body.get("pinUpdated") === "true") {
    try {
      await client.mutate({
        mutation: UPDATE_RFID,
        variables: {
          uid: body.get("uid"),
          is_active: body.get("is_active"),
          event_id: body.get("event_id"),
          last_four_phone_numbers: body.get("last_four_phone_numbers"),
          personnal_pin: body.get("last_four_phone_numbers"),
          reg_id: body.get("reg_id"),
          access_type: body.get("access_type"),
          cash_balance: Math.round(cashBalance * 100),
          promo_balance: Math.round(promoBalance * 100),
          tokens_balance: updatedTokenBalances,
          notes: body.get("notes"),
          last_updated_by: user?.id,
        },
      });
      toast.success("RFID Asset updated successfully");
      return redirect("/rfid-assets/list");
    } catch {
      toast.error("Error updating RFID Asset");
    }
  } else {
    try {
      await client.mutate({
        mutation: UPDATE_RFID_WITHOUT_PIN,
        variables: {
          uid: body.get("uid"),
          is_active: body.get("is_active"),
          event_id: body.get("event_id"),
          reg_id: body.get("reg_id"),
          access_type: body.get("access_type"),
          cash_balance: Math.round(cashBalance * 100),
          promo_balance: Math.round(promoBalance * 100),
          tokens_balance: updatedTokenBalances,
          notes: body.get("notes"),
          last_updated_by: user?.id,
        },
      });
      toast.success("RFID Asset updated successfully");
      return redirect("/rfid-assets/list");
    } catch {
      toast.error("Error updating RFID Asset");
    }
  }
};

export const editRFIDLoader = async ({ params }: LoaderFunctionArgs) => {
  const organizationId = getState().organizationId;
  const { id } = params;
  const user = getUserStore().user;

  let where: any = {
    organization_id: { _eq: organizationId },
  };
  if (user?.events) {
    where = {
      ...where,
      id: {
        _in: user?.events,
      },
    };
  }
  const [{ data: rfid }, { data: attendeesData }, { data: eventsData }] =
    await Promise.all([
      client.query({
        query: RFID_BY_ID_QUERY,
        variables: {
          id,
        },
      }),
      client.query({
        query: GET_LIST_ATTENDEES_BY_ORGANIZATION,
        variables: {
          organizationId,
        },
      }),
      client.query({
        query: GET_LIST_EVENTS_BY_ORGANIZATION,
        variables: {
          where,
        },
      }),
    ]);
  return {
    rfid: rfid.rfid_assets[0],
    attendees: attendeesData.attendees,
    events: eventsData.events,
  };
};

export const RFIDAssetEdit = () => {
  const { rfid, attendees, events } = useLoaderData() as {
    rfid: RFID;
    attendees: Attendee[];
    events: Event[];
  };
  const [pinUpdate, setPinUpdate] = useState(false);
  const selectedAttendee = attendees.find((a) => a.id === rfid.attendee_id);
  const [cashBalance, setCashBalance] = useState(rfid.cash_balance / 100);
  const [promoBalance, setPromoBalance] = useState(rfid.promo_balance / 100);
  const [tokenBalances, setTokenBalances] = useState<TokenBalances>({});

  useEffect(() => {
    if (rfid.tokens_balance) {
      setTokenBalances(rfid.tokens_balance);
    }
  }, [rfid.tokens_balance]);

  const handleTokenBalanceChange = (
    tokenName: string,
    newValue: string | number
  ) => {
    setTokenBalances((prevBalances) => ({
      ...prevBalances,
      [tokenName]: {
        ...prevBalances[tokenName],
        balance: Number(newValue),
      },
    }));
  };

  const renderTokensBalance = () => {
    return Object.entries(tokenBalances).map(([tokenName, { balance }]) => (
      <Input
        key={tokenName}
        label={tokenName}
        name={tokenName}
        type="number"
        defaultValue={balance}
        onChange={(newValue) => {
          if (newValue !== undefined) {
            handleTokenBalanceChange(tokenName, newValue);
          }
        }}
      />
    ));
  };

  const handleCashBalanceChange = (value: number) => {
    setCashBalance(value);
  };

  const handlePromoBalanceChange = (value: number) => {
    setPromoBalance(value);
  };

  return (
    <Form method="post" action={`/rfid-assets/${rfid.id}/edit`}>
      <FormLayout>
        <div className="col-span-6 sm:col-span-1">
          <Input label="UID" name="uid" defaultValue={rfid.uid} />
        </div>
        <div className="col-span-6 sm:col-span-5">
          <IndeterminateCheckbox
            label="First Association Complete?"
            name="is_active"
            defaultChecked={rfid.is_active}
          />
        </div>
        <div className="col-span-6 sm:col-span-6">
          <p className="block text-sm font-medium text-gray-700">Attendee:</p>
          <p className="block text-sm font-small text-gray-700">
            <Link to={`/attendees/${rfid.attendee_id}/edit`}>
              <Badge>
                {selectedAttendee ? selectedAttendee.first_name : ""}{" "}
                {selectedAttendee ? selectedAttendee.last_name : ""}
              </Badge>
            </Link>
          </p>
        </div>
        <div className="col-span-6 sm:col-span-6">
          <CustomSelect
            title="Event"
            name="event_id"
            defaultValue={rfid.event_id}
            options={events.map((e) => ({
              label: e.name,
              value: e.id,
            }))}
          />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Update PIN?"
            name="pinUpdated"
            defaultChecked={false}
            onChange={(checked) => {
              setPinUpdate(checked as boolean);
            }}
          />
        </div>
        {pinUpdate && (
          <div className="col-span-5 sm:col-span-5">
            <Input label="PIN" name="last_four_phone_numbers" required />
          </div>
        )}
        <div className="col-span-6 sm:col-span-6">
          <Input label="Print ID" name="reg_id" defaultValue={rfid.reg_id} />
        </div>
        <div className="col-span-6 sm:col-span-6">
          <Input
            label="Access Type"
            name="access_type"
            defaultValue={rfid.access_type}
          />
        </div>
        <div className="col-span-6 sm:col-span-6">
          <PriceInput
            label="Cash Balance"
            name="cash_balance"
            value={cashBalance}
            onChange={(value) => handleCashBalanceChange(value ? value : 0)}
          />
        </div>
        <div className="col-span-6">
          <PriceInput
            label="Promo Balance"
            name="promo_balance"
            value={promoBalance}
            onChange={(value) => handlePromoBalanceChange(value ? value : 0)}
          />
        </div>
        <div className="col-span-6 sm:col-span-6">
          <p className="block text-sm font-medium text-gray-700">
            Token Balances:
          </p>
          <p className="block text-sm font-small text-gray-700">
            {renderTokensBalance()}
          </p>
        </div>
        <div className="col-span-6">
          <TextArea label="Notes" name="notes" defaultValue={rfid.notes} />
        </div>
        <input
          type="hidden"
          name="token_balances"
          value={JSON.stringify(tokenBalances)}
        />
      </FormLayout>
    </Form>
  );
};
