import { gql } from "@apollo/client";

export const GET_ATTENDEES = gql`
  query attendees($where: attendees_bool_exp) {
    attendees(where: $where) {
      id
      first_name
      last_name
      phone_number
      validation_code
      email
      is_suspended
      event_id
      event {
        name
      }
      __typename
    }
  }
`;

export const GET_ATTENDEE = gql`
  query attendeesByPk($id: Int!) {
    attendees_by_pk(id: $id) {
      id
      first_name
      last_name
      phone_number
      email
      validation_code
      event_id
      event {
        id
        name
      }
      is_suspended
      is_active
      note
    }
  }
`;


export const GET_ATTENDEE_DETAIL = gql`
  query attendeesByPk($id: Int!) {
    attendees_by_pk(id: $id) {
      id
      first_name
      last_name
      phone_number
      email
      validation_code
      event_id
      is_suspended
      is_active
      note
    }
  }
`;

export const GET_ATTENDEE_RFID = gql`
  query GET_ATTENDEE_RFID($id: Int!, $event_id: Int!) {
  rfid_assets(where: {attendee_id: {_eq: $id}, event_id: {_eq: $event_id}}, order_by: {uid: asc}) {
    id
    is_active
    is_qr
    uid
  }
}
`;
export const GET_ATTENDEE_CARD = gql`
  query attendeesRfidCard($id: Int!, $event_id: Int!) {
    card_on_files(where: {attendee_id: {_eq: $id}, event_id: {_eq: $event_id}}, order_by: {updated_at: desc}) {
      id
      last_four_digits
      is_active
    }
  }
`;

export const GET_ATTENDEE_ORDERS = gql`
query attendeeOrders($event_id: Int!, $uids: [String!]) {
  reports_transactions(where: {event_id: {_eq: $event_id}, uid: {_in: $uids}}, order_by: {transaction_time: desc}) {
    card_type
    device_app_id
    digital_surcharge
    event_id
    event_name
    applied_discount_amount
    item_subtotal
    last4
    location_id
    location_name
    order_id
    order_items_count
    order_mx_ref_id
    order_status
    organization_id
    organization_name
    payment_ref_id
    payment_status
    payment_type
    applied_discount_name
    tax
    tip
    transaction_amount
    transaction_date
    transaction_hour
    transaction_status
    transaction_time
    uid
    username
    vendor_id
    vendor_name
  }
}
`;
