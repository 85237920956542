import { useEffect, useState } from "react";
import {
  Form,
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useLoaderData,
  redirect,
  useFetcher,
} from "react-router-dom";
import toast from "react-hot-toast";
import {Input, TextArea} from "../../components/inputs/Input";
import CustomSelect from "../../components/customSelect";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import {
  organizationStore,
} from "../../store/organization";
import {
  UPDATE_INVOICE,
} from "./mutations";
import {GET_LIST_EVENTS_BY_ORGANIZATION, INVOICE_BY_PK} from "./queries";
import { usePermissions } from "../auth/api";
import {Invoice, InvoiceLineItems} from "./type";
import { Event } from "../../types/event";
import DeleteModal from "../../components/DeleteModal";
import {PrimaryButton, SecondaryButton} from "../../components/Button";
import { userStore } from "../../store/user";
import {DatePicker} from "../../components/DatePicker";
import RadioGroup from "../../components/inputs/RadioGroup";
import PriceInput from "../../components/inputs/PriceInput";
import {parseDate} from "@internationalized/date";


const client = createApolloClient();
const { getState } = userStore;
const { getState: getOrganizationStore } = organizationStore;

export const editInvoicesAction = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const { id } = params;
  const body = await request.formData();
  const user = getState().user;
  try {
    await client.mutate({
      mutation: UPDATE_INVOICE,
      variables: {
        id,
        organizationId: body.get("organization_id"),
        eventId: body.get("event_id"),
        fromName: body.get("from_name"),
        fromEmail: body.get("from_email"),
        billToName: body.get("bill_to_name"),
        billToEmail: body.get("bill_to_email") || null,
        billToPhoneNumber: body.get("bill_to_phone_number") || null,
        invoiceNumber: body.get("invoice_number") || null,
        invoiceDate: body.get("invoice_date") || null,
        invoiceDueDate: body.get("invoice_due_date") || null,
        invoiceType: body.get("invoice_type"),
        lineItems: body.get("invoice_line_items"),
        invoiceAmount: body.get("total_amount"),
        invoiceTaxPercentage: body.get("invoice_tax_percentage"),
        invoiceServiceFeePercentage: body.get("invoice_service_fee_percentage"),
        description: body.get("description"),
        totalAmount: body.get("total_amount"),
        totalTax: body.get("total_tax"),
        totalServiceFee: body.get("total_service_fee"),
        grandTotalAmount: body.get("grand_total_amount"),
        lastUpdatedBy: user?.id,
      },
    });
    toast.success("Invoice Updated");
    return redirect(`/invoices/list`);
  } catch (error) {
    toast.error("Unable to Update Invoice: " + error);
  }
};

export const invoicesByIdLoader = async ({ params }: LoaderFunctionArgs) => {
  const organizationId = getOrganizationStore().organizationId;
  const user = getState().user;

  let eventWhere = {};
  let vendorWhere = {};

  if (user?.vendors) {
    vendorWhere = {
      ...vendorWhere,
      id: {
        _in: user?.vendors,
      },
    };
  }

  if (user?.events) {
    eventWhere = {
      ...eventWhere,
      id: {
        _in: user?.events,
      },
    };
  }

  if (organizationId) {
    vendorWhere = {
      ...vendorWhere,
      organization_id: {
        _eq: organizationId,
      },
    };

    eventWhere = {
      ...eventWhere,
      organization_id: { _eq: organizationId },
      is_active: { _eq: true },
    };
  }
  const { id } = params;
  const [{ data: eventsData }, {data: invoicesData}] = await Promise.all([
    client.query({
      query: GET_LIST_EVENTS_BY_ORGANIZATION,
      variables: {
        where: eventWhere,
      },
    }),
    client.query({
      query: INVOICE_BY_PK,
      variables: { id },
    }),
  ]);

  return {
    events: eventsData.events,
    invoices: invoicesData.invoices_by_pk,
  };
};


export const InvoicesEdit = () => {
  const { events, invoices } = useLoaderData() as {
    events: Event[];
    invoices: Invoice;
  };
  const [invoiceType, setInvoiceType] = useState(invoices.invoice_type);
  const [totalPrice, setTotalPrice] = useState(invoices.total_amount);
  const initialLineItems: InvoiceLineItems[] = JSON.parse(invoices.invoice_line_items);
  console.log({initialLineItems})
  const [lineItems, setLineItems] = useState<InvoiceLineItems[]>(initialLineItems);
  const [totalAmount, setTotalAmount] = useState(Number(invoices.total_amount));
  const [totalTax, setTotalTax] = useState(Number(invoices.total_tax));
  const [totalServiceFee, setTotalServiceFee] = useState(Number(invoices.total_service_fee));
  const [grandTotal, setGrandTotal] = useState(Number(invoices.grand_total_amount));
  const defaultInvoiceDate = invoices?.invoice_date
      ? parseDate(invoices?.invoice_date)
      : null;
  const defaultInvoiceDueDate = invoices?.invoice_due_date
      ? parseDate(invoices?.invoice_due_date)
      : null;
  const resetValues = () => {
    setLineItems([{ description: "", quantity: 0, amount: 0, taxPercentage: 0, serviceFeePercentage: 0 }]);
    setTotalAmount(0);
    setTotalTax(0);
    setTotalServiceFee(0);
    setGrandTotal(0);
  };

  const handleAddLineItem = () => {
    setLineItems([...lineItems, { description: "", quantity: 0, amount: 0, taxPercentage: 0, serviceFeePercentage: 0 }]);
  };

  const handleRemoveLastLineItem = () => {
    setLineItems(lineItems.slice(0, -1));
  };

  const handleLineItemChange = (
      index: number,
      field: keyof InvoiceLineItems,
      value: string | number | undefined
  ) => {
    const newLineItems = [...lineItems];
    newLineItems[index] = {
      ...newLineItems[index],
      [field]: value,
    } as InvoiceLineItems;
    setLineItems(newLineItems);
  };

  const handleInvoiceTypeChange = (value: string) => {
    setInvoiceType(value);
    resetValues();
  };

  const calculateTotals = () => {
    let amount = 0;
    let tax = 0;
    let serviceFee = 0;

    if (Array.isArray(lineItems)) {
      lineItems.forEach(item => {
        console.log(item.amount);
        const itemAmount = Number(item.amount) * (invoiceType === "total" ? 1 : Number(item.quantity));
        const itemTax = (itemAmount * Number(item.taxPercentage)) / 100;
        const itemServiceFee = (Math.ceil((itemAmount + itemTax) * Number(item.serviceFeePercentage))) / 100;

        amount += itemAmount;
        tax += itemTax;
        serviceFee += itemServiceFee;
      });
    }

    setTotalAmount(amount);
    setTotalTax(tax);
    setTotalServiceFee(serviceFee);
    setGrandTotal(amount + tax + serviceFee);
  };

  useEffect(() => {
    calculateTotals();
  }, [lineItems]);

  const handlePriceChange = (value: number) => {
    setTotalPrice(value);
  };

  const { deletePermission } = usePermissions("invoices");
  const fetcher = useFetcher();
  return (
      <Form method="put" action={`/invoices/${invoices.id}/edit`}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}>
        {deletePermission && (
            <div className="flex">
              <div className="ml-auto py-2">
                <DeleteModal
                    onConfirm={() => {
                      fetcher.submit(
                          {},
                          {
                            method: "delete",
                            action: `/invoices/${invoices.id}/delete`,
                          }
                      );
                    }}
                >
                  {({ setOpen }) => (
                      <SecondaryButton onClick={() => setOpen(true)}>
                        Delete
                      </SecondaryButton>
                  )}
                </DeleteModal>
              </div>
            </div>
        )}
        <FormLayout>
          <div className="col-span-6">
            <CustomSelect
                title="Event"
                name="event_id"
                defaultValue={invoices.event_id}
                required
                options={[
                  ...(events?.map((e) => ({
                    label: e.name,
                    value: e.id,
                  })) || []),
                ]}
            />
          </div>
          <div className="col-span-6">
            <Input
                label="From Name"
                name="from_name"
                defaultValue={invoices.from_name}
                required
            />
          </div>
          <div className="col-span-6">
            <Input
                label="From Email"
                name="from_email"
                defaultValue={invoices.from_email}
                required
                type="email"
            />
          </div>
          <div className="col-span-6">
            <Input
                label="Bill To Name"
                name="bill_to_name"
                defaultValue={invoices.bill_to_name}
                required
            />
          </div>
          <div className="col-span-6">
            <Input
                label="Bill To Email"
                name="bill_to_email"
                defaultValue={invoices.bill_to_email}
            />
          </div>
          <div className="col-span-6">
            <Input
                label="Bill To Phone Number"
                name="bill_to_phone_number"
                defaultValue={invoices.bill_to_phone_number}
            />
          </div>
          <div className="col-span-6">
            <Input
                label="Invoice Number"
                name="invoice_number"
                defaultValue={invoices.invoice_number}
            />
          </div>
          <div className="col-span-3">
            <DatePicker
                label="Invoice Date"
                name="invoice_date"
                defaultValue={defaultInvoiceDate}
            />
          </div>
          <div className="col-span-3">
            <DatePicker
                label="Invoice Due Date"
                name="invoice_due_date"
                defaultValue={defaultInvoiceDueDate}
            />
          </div>
          <div className="col-span-6">
            <RadioGroup
                label="Total or Line Item"
                name="invoice_type"
                defaultChecked={invoices.invoice_type}
                onChange={handleInvoiceTypeChange}
                choices={[
                  {title: "Total", id: "total"},
                  {title: "Line Item", id: "line_item"}
                ]}
            />
          </div>
          {invoiceType === "total" && (
              <>
                <div className="col-span-4">
                  <PriceInput
                      label="Invoice Amount"
                      name="invoice_amount"
                      value={totalPrice}
                      required
                      onChange={(value) => {
                        handleLineItemChange(0, 'amount', value);
                        handlePriceChange(value ? value : 0);
                      }}
                  />
                </div>
                <div className="col-span-1">
                  <Input
                      label="Tax % ( 7.5 = 7.5% )"
                      name="invoice_tax_percentage"
                      value={invoices.tax_percentage}
                      placeholder="0"
                      onChange={(value) => handleLineItemChange(0, 'taxPercentage', Number(value))}
                  />
                </div>
                <div className="col-span-1">
                  <Input
                      label="Service Fee % ( 7.5 = 7.5% )"
                      name="invoice_service_fee_percentage"
                      value={invoices.service_fee_percentage}
                      placeholder="0"
                      onChange={(value) => handleLineItemChange(0, 'serviceFeePercentage', Number(value))}
                  />
                </div>
                <div className="col-span-6">
                  <TextArea
                      label="Invoice Description"
                      name="description"
                      defaultValue={invoices.description}
                  />
                </div>
              </>
          )}
          {invoiceType === "line_item" && (
              <>
                {lineItems.map((item, index) => (
                    <div className="col-span-6 grid grid-cols-6 gap-6" key={index}>
                      <div className="col-span-2">
                        <TextArea
                            label="Item Description"
                            name={`line_item_description_${index}`}
                            value={item.description} // Bind to state
                            onChange={(value) => handleLineItemChange(index, 'description', value)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                            label="Quantity"
                            name={`line_item_quantity_${index}`}
                            value={item.quantity} // Bind to state
                            placeholder="0"
                            onChange={(value) => handleLineItemChange(index, 'quantity', Number(value))}
                        />
                      </div>
                      <div className="col-span-1">
                        <PriceInput
                            label="Amount"
                            name={`line_item_amount_${index}`}
                            value={item.amount} // Bind to state
                            onChange={(value) => handleLineItemChange(index, 'amount', Number(value))}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                            label="Tax % ( 7.5 = 7.5% )"
                            name={`line_item_tax_percentage_${index}`}
                            value={item.taxPercentage} // Bind to state
                            placeholder="0"
                            onChange={(value) => handleLineItemChange(index, 'taxPercentage', Number(value))}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                            label="Service Fee % ( 7.5 = 7.5% )"
                            name={`line_item_service_fee_percentage_${index}`}
                            value={item.serviceFeePercentage} // Bind to state
                            placeholder="0"
                            onChange={(value) => handleLineItemChange(index, 'serviceFeePercentage', Number(value))}
                        />
                      </div>
                    </div>
                ))}
                <div className="col-span-6 flex gap-4">
                  <PrimaryButton
                      type="button"
                      onClick={handleAddLineItem}
                  >
                    Add New Line Item
                  </PrimaryButton>
                  <PrimaryButton
                      type="button"
                      onClick={handleRemoveLastLineItem}
                  >
                    Remove Last Line Item
                  </PrimaryButton>
                </div>
              </>
          )}
          <div className="col-span-6">
            <h3 className="text-lg font-semibold">Invoice Preview</h3>
            <div>Total Amount: ${totalAmount.toFixed(2)}</div>
            <div>Total Tax: ${totalTax.toFixed(2)}</div>
            <div>Total Service Fee: ${totalServiceFee.toFixed(2)}</div>
            <div>Grand Total: ${grandTotal.toFixed(2)}</div>
          </div>
          <input
              type="hidden"
              value={invoices.organization_id || 0}
              name="organization_id"
          />
          <input
              type="hidden"
              value={JSON.stringify(lineItems)}
              name="invoice_line_items"
          />
          <input
              type="hidden"
              value={invoiceType}
              name="invoice_type"
          />
          <input
              type="hidden"
              value={totalAmount}
              name="total_amount"
          />
          <input
              type="hidden"
              value={totalTax}
              name="total_tax"
          />
          <input
              type="hidden"
              value={totalServiceFee}
              name="total_service_fee"
          />
          <input
              type="hidden"
              value={grandTotal}
              name="grand_total_amount"
          />
        </FormLayout>
      </Form>
  );
};
