import { ActionFunctionArgs, Form, redirect, useLoaderData } from "react-router-dom";
import toast from "react-hot-toast";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import { Input } from "../../components/inputs/Input";
import PriceInput from "../../components/inputs/PriceInput";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { CREATE_ITEM_MODIFIER } from "./mutations";
import { useState } from "react";
import { userStore } from "../../store/user";
import CustomSelect from "../../components/customSelect";
import {
  organizationStore,
  useOrganizationStore,
} from "../../store/organization";
import {
  GET_LIST_EVENTS_BY_ORGANIZATION,
  GET_LIST_VENDORS_BY_ORGANIZATION,
} from "../../queries";
import { GET_ITEM_MODIFIERS_TYPE } from "./queries";
import { Categories } from "../../types/item";

const client = createApolloClient();
const { getState } = userStore;
const { getState: getOrganizationStore } = organizationStore;

export const itemCreateLoader = async () => {
  const organizationId = getOrganizationStore().organizationId;
  const { data } = await client.query({
    query: GET_LIST_VENDORS_BY_ORGANIZATION,
    variables: {
      where: organizationId
          ? {
            organization_id: {
              _eq: organizationId,
            },
          }
          : {},
    },
  });

  const { data: eventsData } = await client.query({
    query: GET_LIST_EVENTS_BY_ORGANIZATION,
    variables: {
      where: organizationId
          ? {
            organization_id: {
              _eq: organizationId,
            },
          }
          : {},
    },
  });

  const { data: itemsModifierTypeData } = await client.query({
    query: GET_ITEM_MODIFIERS_TYPE,
    variables: {
      where: organizationId
          ? {
            organization_id: {
              _in: [0, organizationId],
            },
            is_active: {
              _eq: true,
            },
            reference_type: {
              _eq: "item_modifiers_type",
            },
          }
          : {},
    },
  });

  const { data: itemsModifierSubTypeData } = await client.query({
    query: GET_ITEM_MODIFIERS_TYPE,
    variables: {
      where: organizationId
          ? {
            organization_id: {
              _in: [0, organizationId],
            },
            is_active: {
              _eq: true,
            },
            reference_type: {
              _eq: "item_modifiers_sub_type",
            },
          }
          : {},
    },
  });
  return {
    vendors: data.vendors,
    events: eventsData.events,
    itemsModifierTypeData: itemsModifierTypeData.dashboard_reference,
    itemsModifierSubTypeData: itemsModifierSubTypeData.dashboard_reference,
  };
};

export const itemCreateAction = async ({
                                         params,
                                         request,
                                       }: ActionFunctionArgs) => {
  const body = await request.formData();
  const user = getState().user;

  const priceString = body.get("additional_price") as string;
  const price = parseFloat(priceString.length ? priceString : "0");
  let input;
  let bodyData: any = Object.fromEntries(body);
  bodyData = {
    ...bodyData,
  };
  input = {
    ...bodyData,
    additional_price: price * 100,
    last_updated_by: user?.id,
  };

  try {
    const { data } = await client.mutate({
      mutation: CREATE_ITEM_MODIFIER,
      variables: {
        input,
      },
    });

    toast.success("Item Modifier Created");

    return redirect(`/item-modifiers/list`);
  } catch (error: any) {
    const errorType = error?.graphQLErrors?.[0]?.extensions?.code;
    const message = error?.graphQLErrors?.[0]?.message;
    if (errorType === "constraint-violation") {
      toast.error(message);
    } else {
      toast.error("Failed to create item" + error);
    }
    return redirect("/item-modifiers/create");
  }
};

export const ItemModifierCreate = () => {
  const organizations = useOrganizationStore((state) => state.organizations);
  const organizationId = getOrganizationStore().organizationId;
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [selectedSubType, setSelectedSubType] = useState<string | null>(null);
  const { itemsModifierTypeData, itemsModifierSubTypeData } = useLoaderData() as {
    itemsModifierTypeData: Categories[];
    itemsModifierSubTypeData: Categories[];
  };
  const [price, setPrice] = useState(0);
  const filterSubTypes = (type: string) => {
    return itemsModifierSubTypeData.filter(subType => subType.filter_value === type);
  };
  const handlePriceChange = (value: number) => {
        setPrice(value);
    };
  const subTypes = selectedType ? filterSubTypes(selectedType) : [];

  return (
      <Form
          method="post"
          action={`/item-modifiers/create`}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
      >
          <FormLayout>
              <div className="col-span-5">
                  <CustomSelect
                      title="Type"
                      name="type"
                      onChange={(v) => {
                          setSelectedSubType(null);
                          setSelectedType(v);
                      }}
                      options={[
                          ...(itemsModifierTypeData?.map((v) => ({
                              label: v.reference_label,
                              value: v.reference_value,
                          })) || []),
                      ]}
                  />
              </div>
              <div className="col-span-1">
                  <IndeterminateCheckbox
                      label="Is Active?"
                      name="is_active"
                      defaultChecked={true}
                  />
              </div>
              {selectedType && (
                  <div className="col-span-6">
                      <CustomSelect
                          key={selectedType}
                          title="Sub-Type"
                          name="sub_type"
                          defaultValue={selectedSubType}
                          onChange={(v) => setSelectedSubType(v)}
                          options={[
                              ...(subTypes?.map((v) => ({
                                  label: v.reference_label,
                                  value: v.reference_value,
                              })) || []),
                          ]}
                      />
                  </div>
              )}
              <div className="col-span-6">
                  <Input label="Modifier Name" name="name"/>
              </div>
              <div className="col-span-6">
                  <PriceInput
                      label="Additional Price"
                      name="additional_price"
                      value={price}
                      onChange={(value) => handlePriceChange(value ? value : 0)}
                  />
              </div>
              <input
                  type="hidden"
                  value={organizationId || 0}
                  name="organization_id"
              />
          </FormLayout>
      </Form>
  );
};
