import {
  Link,
  useLoaderData,
  LoaderFunctionArgs,
  useNavigate,
  useRevalidator,
} from "react-router-dom";
import { Badge } from "../../components/Badge";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { VENDORS_LIST_QUERY } from "./queries";
import { organizationStore } from "../../store/organization";
import { Vendor } from "../../types/vendor";
import { useEffect, useRef, useState } from "react";
import { eventStore } from "../../store/event";
import moment from "moment/moment";
import { usePermissions } from "../auth/api";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {
  ColDef,
  GridApi,
  SizeColumnsToContentStrategy,
} from "ag-grid-community";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Input } from "../../components/inputs/Input";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import { AgGridReact } from "ag-grid-react";
import { usdFormat } from "../../utils";
import { userStore } from "../../store/user";
import VendorImportCSVModal from "../../components/importCsv/VendorImportCSVModal";

const client = createApolloClient();
const { getState } = organizationStore;
const { getState: getEventStore } = eventStore;
const { getState: getUserStore } = userStore;

const buildSearch = (search: string) => [
  {
    name: {
      _ilike: `%${search}%`,
    },
  },
  {
    type: {
      _ilike: `%${search}%`,
    },
  },
  {
    other_type: {
      _ilike: `%${search}%`,
    },
  },
];

type CellRenderer = (params: {
  value:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  data: Vendor;
}) => JSX.Element;

const createCellRenderer = (
  permission: boolean,
  linkFunc: (params: { data: Vendor }) => string
): CellRenderer => {
  return (params) => (
    <>
      {permission ? (
        <Link to={linkFunc(params)}>
          <Badge>{params.value}</Badge>
        </Link>
      ) : (
        <Badge>{params.value}</Badge>
      )}
    </>
  );
};

export const vendorsListLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const search = url.searchParams.get("search");
  const rawOrganizationId = getState().organizationId;
  const organizationId = parseInt(rawOrganizationId !== null ? rawOrganizationId.toString() : '', 10);
  const eventId = getEventStore().eventId;
  const user = getUserStore().user;

  let where: any = {
    _or: search ? buildSearch(search) : undefined,
  };
  if (organizationId && organizationId !== 0) {
    where = {
      ...where,
      organization_id: {
        _eq: organizationId,
      },
    };
  }
  if ((user?.vendors?.length || 0) > 0) {
    where = {
      ...where,
      id: {
        _in: user?.vendors,
      },
    };
  }
  const { data } = await client.query({
    query: VENDORS_LIST_QUERY,
    variables: {
      where,
    },
  });
  return data.vendors;
};

export const VendorList = () => {
  const { editCreatePermission, ListPermission, moduleWisePermission } =
    usePermissions("vendors");
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const gridRef = useRef<GridApi>();
  const vendors = useLoaderData() as Vendor[];
  const [rowData, setRowData] = useState<Vendor[]>([]);
  useEffect(() => {
    setRowData(vendors);
  }, [vendors]);
  const generateColDefs = (): ColDef<Vendor>[] => [
    {
      headerName: "Id",
      valueGetter: (p) => p.data?.id,
      cellRenderer: createCellRenderer(
        editCreatePermission,
        (p) => `/vendors/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Vendor Name",
      valueGetter: (p) => p.data?.name,
      cellRenderer: createCellRenderer(
        editCreatePermission,
        (p) => `/vendors/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Is Active?",
      valueGetter: (p) => p.data?.is_active,
      valueFormatter: (params) => {
        return params.value ? "Yes" : "No";
      },
    },
    {
      headerName: "Vendor Type",
      valueGetter: (p) => p.data?.type,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Point-of-Contact Name",
      valueGetter: (p) => p.data?.poc_name,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Point-of-Contact Email",
      valueGetter: (p) => p.data?.poc_email,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Point-of-Contact Phone Number",
      valueGetter: (p) => p.data?.poc_phone,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Revenue Share",
      valueGetter: (p) => p.data?.revenue_share,
      valueFormatter: (p) => {
        return p.value ? `${((p.value || 0) * 100).toFixed(2)}%` : "";
      },
    },
    {
      headerName: "Sales Tax Responsible?",
      valueGetter: (p) => p.data?.sales_tax_responsible,
      valueFormatter: (params) => {
        const value = params.value ?? 0;
        return value ? "Yes" : "No";
      },
    },
    {
      headerName: "Flat Fee",
      valueGetter: (p) => p.data?.flat_fee,
      valueFormatter: (params) =>
        params.value ? usdFormat.format((params.value ?? 0) / 100) : "-",
    },
    {
      headerName: "Updated By",
      valueGetter: (p) => p.data?.user?.username,
    },
    {
      headerName: "Created Date",
      valueGetter: (p) => p.data?.created_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
    {
      headerName: "Updated Date",
      valueGetter: (p) => p.data?.updated_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
  ];
  const colDefs: ColDef<Vendor>[] = generateColDefs();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const handleExportCSV = () => {
    if (gridRef.current) {
      const timestamp = new Date().toISOString().replace(/[-:]/g, "");
      const params = {
        fileName: `Vendors_${timestamp}.csv`,
        columnSeparator: ",",
      };
      gridRef.current.exportDataAsCsv(params); // Updated line
    }
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };
  return (
    <div style={{ height: "75vh" }}>
      <div
        className={"ag-theme-quartz"}
        style={{ width: "100%", height: "100%" }}
      >
        <div className=" grid gap-2 grid-cols-6">
          <Breadcrumbs />
          <div className="col-span-2 col-start-5 mt-2">
            <Input
              name="search"
              placeholder={`Search Vendors`}
              onChange={(s) => {
                navigate(
                  s && typeof s === "string" && s.length > 0
                    ? `/vendors/list?search=${s}`
                    : `/vendors/list`
                );
                revalidator.revalidate();
              }}
            />
          </div>
          <div className="py-2 col-span-2 col-start-7">
            <VendorImportCSVModal onConfirm={() => {}}>
              {({ setOpen }) => (
                <PrimaryButton onClick={() => setOpen(true)}>
                  Import CSV
                </PrimaryButton>
              )}
            </VendorImportCSVModal>
            <SecondaryButton onClick={handleExportCSV}>
              Export to CSV
            </SecondaryButton>
            {editCreatePermission && (
              <PrimaryButton onClick={() => navigate(`/vendors/create`)}>
                Create
              </PrimaryButton>
            )}
          </div>
        </div>
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          rowHeight={rowHeight}
          autoSizeStrategy={autoSizeStrategy}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={100}
          onGridReady={(params) => (gridRef.current = params.api)}
        />
      </div>
    </div>
  );
};
