import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { classNames } from "../../utils";

interface InputProps {
    label?: string;
    error?: string;
    type?: string;
    name: string;
    placeholder?: string;
    required?: boolean;
    onChange?: (value: string | number | undefined) => void;
    defaultValue?: string | number;
    value?: string | number;
    maxLength?: number;
    max?: number;
    updateValue?: boolean;
    onEnter?: (value: string | number | undefined) => void;
}

export function Input({
                          label,
                          error,
                          name,
                          type = "text",
                          placeholder,
                          required,
                          defaultValue,
                          value, // controlled value
                          onChange,
                          updateValue = false,
                          ...rest
                      }: InputProps) {
    const [internalValue, setInternalValue] = useState(defaultValue);

    // Sync the internal state when updateValue is true
    useEffect(() => {
        if (updateValue) {
            setInternalValue(defaultValue);
        }
    }, [updateValue, defaultValue]);

    // Handle change
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInternalValue(e.target.value); // Update internal state for controlled component behavior
        if (onChange) {
            onChange(e.target.value); // Send value to parent if onChange exists
        }
    };

    return (
        <div>
            {label && (
                <label htmlFor={name} className="block text-sm font-medium text-gray-700">
                    {label} {required && <span className="text-red-500">*</span>}
                </label>
            )}
            <div className="relative mt-1 rounded-md shadow-sm">
                <input
                    id={name}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    value={value !== undefined ? value : internalValue} // If value is provided, use it, else use internalValue
                    onChange={handleChange}
                    className={classNames(
                        "block w-full rounded-md border-gray-300 pr-10 sm:text-sm",
                        error
                            ? "text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
                            : ""
                    )}
                    {...rest}
                />
                {error && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>
                )}
            </div>
            {error && (
                <p className="mt-2 text-sm text-red-600" id="error">
                    {error}
                </p>
            )}
        </div>
    );
}

export function TextArea({
                           label,
                           error,
                           name,
                           placeholder,
                           defaultValue,
                           onChange,
                           ...rest
                         }: InputProps) {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e.target.value); // Pass the value to the parent
    }
  };

  return (
      <div>
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <div className="relative mt-1 rounded-md shadow-sm">
        <textarea
            id={name}
            name={name}
            className={classNames(
                "block w-full rounded-md border-gray-300 pr-10 sm:text-sm",
                error
                    ? "text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
                    : ""
            )}
            placeholder={placeholder}
            value={value} // Controlled value
            onChange={handleChange} // Use the handleChange function
            {...rest}
        />
          {error && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                />
              </div>
          )}
        </div>
        {error && (
            <p className="mt-2 text-sm text-red-600" id="error">
              {error}
            </p>
        )}
      </div>
  );
}

export function PasswordInput({
  label,
  error,
  name,
  placeholder,
  required,
  defaultValue,
  onChange,
  onEnter,
  ...rest
}: InputProps) {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange && onChange(e.target.value);
  };

  const toggleVisible = () => setVisible(!visible);

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          id={name}
          name={name}
          type={visible ? "text" : "password"}
          onChange={handleChange}
          className={classNames(
            "block w-full rounded-md border-gray-300 pr-10 sm:text-sm",
            error
              ? "text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
              : ""
          )}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onEnter && onEnter(value);
            }
          }}
          placeholder={placeholder}
          defaultValue={defaultValue}
          required={required}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
        {visible ? (
          <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3">
            <EyeSlashIcon
              onClick={toggleVisible}
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        ) : (
          <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3">
            <EyeIcon
              onClick={toggleVisible}
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="error">
          {error}
        </p>
      )}
    </div>
  );
}
