import {
  ActionFunctionArgs,
  Form,
  useLoaderData,
  redirect,
} from "react-router-dom";
import toast from "react-hot-toast";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import { Input } from "../../components/inputs/Input";
import CustomSelect from "../../components/customSelect";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import {
  GET_LIST_EVENTS_BY_ORGANIZATION,
  GET_LIST_VENDORS_BY_ORGANIZATION,
  LOCATIONS_BY_ORGANIZATION,
  QUERY_LIST_ITEMS,
} from "../../queries";
import { organizationStore } from "../../store/organization";
import { Item } from "../../types/item";
import { Location } from "../../types/location";
import { Vendor } from "../../types/vendor";
import {
  CREATE_LOCATION_MENUS,
  CREATE_MENU,
  CREATE_MENU_ITEMS,
} from "./mutations";
import { usePermissions } from "../auth/api";
import { userStore } from "../../store/user";
import { useEffect, useState } from "react";
import { usdFormat } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Event } from "../../types/event";


type LocationsIds = string;
type ItemsIds = string;
const client = createApolloClient();
const { getState } = organizationStore;
const { getState: getUserState } = userStore;

export const menuCreateLoader = async () => {
  const organizationId = getState().organizationId;
  const user = getUserState().user;

  let vendorWhere = {};
  let locationWhere: any = {};
  let queryWhere: any = {};
  let eventWhere = {};

  locationWhere = {
    ...locationWhere,
    is_active: { _eq: true },
  };

  queryWhere = {
    ...queryWhere,
    is_active: { _eq: true },
  };
  if (user?.vendors) {
    vendorWhere = {
      ...vendorWhere,
      id: {
        _in: user?.vendors,
      },
    };
  }
  if (user?.events) {
    eventWhere = {
      ...eventWhere,
      id: {
        _in: user?.events,
      },
    };
  }
  if (user?.events) {
    locationWhere = {
      ...locationWhere,
      event: {
        id: {
          _in: user?.events,
        },
      },
    };
    queryWhere = {
      ...queryWhere,
      event: {
        id: {
          _in: user?.events,
        },
      },
    };
  }
  if (organizationId) {
    vendorWhere = {
      ...vendorWhere,
      organization_id: {
        _eq: organizationId,
      },
    };

    eventWhere = {
      ...eventWhere,
      organization_id: {
        _eq: organizationId,
      },
    }

    locationWhere = {
      ...locationWhere,
      event: {
        ...(locationWhere?.event || {}),
        organization_id: {
          _eq: organizationId,
        },
      },
    };

    if (user?.vendors) {
      locationWhere = {
        ...locationWhere,
        vendor_id: {
          _in: user?.vendors,
        },
      };
    }

    queryWhere = {
      ...queryWhere,
      event: {
        ...(queryWhere?.event || {}),
        organization_id: {
          _eq: organizationId,
        },
      },
    };
  }

  const [{ data: itemData }, { data: vendorData }, { data: locationData }, {data: eventsData}] =
    await Promise.all([
      client.query({
        query: QUERY_LIST_ITEMS,
        variables: {
          where: queryWhere,
          order_by: {
            name: "asc",
          },
        },
      }),
      client.query({
        query: GET_LIST_VENDORS_BY_ORGANIZATION,
        variables: {
          where: vendorWhere,
        },
      }),
      client.query({
        query: LOCATIONS_BY_ORGANIZATION,
        variables: {
          where: locationWhere,
        },
      }),
      client.query({
        query: GET_LIST_EVENTS_BY_ORGANIZATION,
        variables: {
          where: eventWhere,
        },
      }),
    ]);
  return {
    vendors: vendorData.vendors,
    items: itemData.items,
    locations: locationData.locations,
    events: eventsData.events,
  };
};

export const menuCreateAction = async ({ request }: ActionFunctionArgs) => {
  const permissions = getUserState().permissions;
  const user = getUserState().user;

  const body = await request.formData();
  const locationsIds: LocationsIds[] = body.getAll(
    "locationsIds"
  ) as LocationsIds[];
  const itemsIds: ItemsIds[] = body.getAll("itemsIds") as ItemsIds[];

  body.delete("itemsIds");
  body.delete("locationsIds");

  const arrayFromValue = Array.from(
    { length: 6 },
    (_, valueIndex) => valueIndex + 1
  );
  const keys: Array<any> = [];
  arrayFromValue.forEach((value: number) => {
    keys.push(`cat_${value}`, `cat_${value}_hide`, `cat_${value}_items`);
  });
  const allItemsValue = body.get("all_items");
  let allItemsIds: string[] = [];
  if (typeof allItemsValue === "string") {
    allItemsIds = JSON.parse(allItemsValue);
  }
  const allItemsIdsStringArray = allItemsIds.map((id) => id.toString());
  body.delete("all_items");
  const bodyData: any = Object.fromEntries(body);
  const category = {
    cat_1: bodyData?.cat_1 || "",
    cat_1_hide: bodyData?.cat_1_hide === "true",
    cat_1_itemsIds: bodyData?.cat_1_items
      ?.split(",")
      ?.filter((item: string) => item && allItemsIdsStringArray.includes(item)),
    cat_2: bodyData?.cat_2,
    cat_2_hide: bodyData?.cat_2_hide === "true",
    cat_2_itemsIds: bodyData.cat_2_items
      ?.split(",")
      ?.filter((item: string) => item && allItemsIdsStringArray.includes(item)),
    cat_3: bodyData?.cat_3,
    cat_3_hide: bodyData?.cat_3_hide === "true",
    cat_3_itemsIds: bodyData.cat_3_items
      ?.split(",")
      ?.filter((item: string) => item && allItemsIdsStringArray.includes(item)),
    cat_4: bodyData?.cat_4,
    cat_4_hide: bodyData?.cat_4_hide === "true",
    cat_4_itemsIds: bodyData.cat_4_items
      ?.split(",")
      ?.filter((item: string) => item && allItemsIdsStringArray.includes(item)),
    cat_5: bodyData?.cat_5,
    cat_5_hide: bodyData?.cat_5_hide === "true",
    cat_5_itemsIds: bodyData.cat_5_items
      ?.split(",")
      ?.filter((item: string) => item && allItemsIdsStringArray.includes(item)),
    cat_6: bodyData?.cat_6,
    cat_6_hide: bodyData?.cat_6_hide === "true",
    cat_6_itemsIds: bodyData.cat_6_items
      ?.split(",")
      ?.filter((item: string) => item && allItemsIdsStringArray.includes(item)),
  };
  keys?.forEach((key: string) => delete bodyData[key]);
  try {
    const { data } = await client.mutate({
      mutation: CREATE_MENU,
      variables: {
        input: { ...bodyData, category, last_updated_by: user?.id },
      },
    });

    const mutationPromises = [];

    if (locationsIds.some((locationId) => locationId.trim() !== "")) {
      mutationPromises.push(
        client.mutate({
          mutation: CREATE_LOCATION_MENUS,
          variables: {
            objects: locationsIds
              .map((locationId) => ({
                location_id: locationId,
                menu_id: data.insert_menus_one.id,
              }))
              .filter((location) => location.location_id.trim() !== ""),
          },
        })
      );
    }

    if (itemsIds.some((itemId) => itemId.trim() !== "")) {
      mutationPromises.push(
        client.mutate({
          mutation: CREATE_MENU_ITEMS,
          variables: {
            objects: itemsIds
              .map((itemId) => ({
                item_id: itemId,
                menu_id: data.insert_menus_one.id,
              }))
              .filter((item) => item.item_id.trim() !== ""),
          },
        })
      );
    }

    // Execute all promises concurrently
    await Promise.all(mutationPromises);

    toast.success("Menu updated successfully");
    return redirect("/menus/list");
  } catch (error) {
    toast.error("Error creating menu: " + error);
    return {};
  }
};

export const MenuCreate = () => {
  const { permissions } = usePermissions();
  const [selectedEvent, setSelectedEvent] = useState<string>();
  const [selectedVendor, setSelectedVendor] = useState<string>();

  const {
    items: allItems,
    vendors,
    locations,
    events,
  } = useLoaderData() as {
    items: Item[];
    vendors: Vendor[];
    locations: Location[];
    events: Event[];
  };
  const organizationId = getState().organizationId;
  const [filteredLocations, setFilteredLocations] =
    useState<Location[]>(locations);
  const {
    data: itemsData,
    isLoading: itemsLoading,
    isFetching: itemsIsFetching,
    error: itemsError,
  } = useQuery<{
    itemsData: Item[];
  }>(
    [
      "table-data-menu-item-download",
      organizationId,
      selectedEvent,
      selectedVendor,
    ],
    async () => {
      let where: any = {};

      if (selectedEvent && selectedVendor) {
        if (organizationId) {
          where = {
            ...where,
            is_active: { _eq: true },
            event: {
              organization_id: { _eq: organizationId },
            },
          };
        }
        where = {
          ...where,
          is_active: { _eq: true },
          event_id: { _eq: selectedEvent },
          vendor_id: { _in: selectedVendor },
        };

        const { data, errors } = await client.query({
          query: QUERY_LIST_ITEMS,
          variables: {
            where,
            order_by: {
              name: "asc",
            },
          },
        });
        return {
          itemsData: data.items,
        };
      }
      return {
        itemsData: [],
      };
    }
  );
  const items = itemsData?.itemsData || [];
  const {
    data: itemsAllVendorsData,
    isLoading: itemsAllVendorsLoading,
    isFetching: itemsAllVendorsIsFetching,
    error: itemsAllVendorsError,
  } = useQuery<{
    itemsAllVendorsData: Item[];
  }>(
    [
      "table-data-menu-item-download-all-vendors",
      organizationId,
      selectedEvent,
    ],
    async () => {
      let where: any = {};

      if (selectedEvent) {
        if (organizationId) {
          where = {
            ...where,
            is_active: { _eq: true },
            event: {
              organization_id: { _eq: organizationId },
            },
          };
        }
        where = {
          ...where,
          is_active: { _eq: true },
          event_id: { _eq: selectedEvent },
          vendor_id: { _eq: "0" }, // Use "0" as the vendor_id
        };

        const { data, errors } = await client.query({
          query: QUERY_LIST_ITEMS,
          variables: {
            where,
            order_by: {
              name: "asc",
            },
          },
        });
        return {
          itemsAllVendorsData: data.items,
        };
      }
      return {
        itemsAllVendorsData: [],
      };
    }
  );
  const itemsAllVendors = itemsAllVendorsData?.itemsAllVendorsData || [];
  const itemIds = items
    .map((item) => item.id)
    .concat(itemsAllVendors.map((item) => item.id));
  const [categoryData, setCategory] = useState<any>({
    cat_1: "",
    cat_1_hide: false,
    cat_1_items: [],
    cat_1_itemsData: items,
    cat_2: "",
    cat_2_hide: false,
    cat_2_items: [],
    cat_2_itemsData: items,
    cat_3: "",
    cat_3_hide: false,
    cat_3_items: [],
    cat_3_itemsData: items,
    cat_4: "",
    cat_4_hide: false,
    cat_4_items: [],
    cat_4_itemsData: items,
    cat_5: "",
    cat_5_hide: false,
    cat_5_items: [],
    cat_5_itemsData: items,
    cat_6: "",
    cat_6_hide: false,
    cat_6_items: [],
    cat_6_itemsData: items,
  });

  const [tips, setTips] = useState(true);
  const [discounts, setDiscounts] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [isDiscountProtected, setIsDiscountProtected] = useState(false);
  const [isPrintingAuto, setIsPrintingAuto] = useState(false);
  const [isPrintingCopies, setIsPrintingCopies] = useState<number>(0);
  const onCheckUnCheckItem = (
    id: number,
    checked: any,
    item_category: string,
    index: number
  ) => {
    const checkedValues = categoryData[item_category];
    const newItemCategory: number[] = [...(checkedValues || [])];
    if (checked) {
      newItemCategory.push(id);
    } else {
      const itemIndex = newItemCategory.indexOf(id);
      newItemCategory.splice(itemIndex, 1);
    }
    setCategory({
      ...categoryData,
      [item_category]: newItemCategory,
    });
  };
  useEffect(() => {
    if (selectedEvent) {
      const updatedLocations = locations.filter(
        (location) => location.event_id.toString() === selectedEvent.toString()
      );
      setFilteredLocations(updatedLocations);
    } else {
      setFilteredLocations([]);
    }
  }, [selectedEvent, locations]);

  return (
    <Form method="post" action="/menus/create">
      <div className="py-4">
        <Breadcrumbs />
      </div>
      <FormLayout isTopSave={true}>
        <div className="col-span-5 sm:col-span-3">
          <Input label="Name" name="name" required />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Is Active?"
            name="is_active"
            defaultChecked
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
              title="Event"
              name="event_id"
              required
              options={[
                ...(events?.map((e) => ({
                  label: e.name,
                  value: e.id,
                })) || []),
              ]}
              onChange={(e) => setSelectedEvent(e)}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
              title="Vendor"
              required
              name="vendor_id"
              options={[
                ...vendors.map((vendor) => ({
                  label: vendor.name,
                  value: vendor.id,
                })),
              ]}
              onChange={(e) => setSelectedVendor(e)}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Locations"
            name="locationsIds"
            options={[
              ...filteredLocations.map((location) => ({
                label: location.name,
                value: location.id,
              })),
            ]}
            multi
          />
        </div>
        <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
          <label className="col-span-6 block text-sm font-medium text-gray-700">
            Accepted Payment Methods
          </label>
          <div className="col-span-3 sm:col-span-1">
            <IndeterminateCheckbox
                label="Cash?"
                name="is_cash"
                defaultChecked
            />
          </div>
          <div className="col-span-3 sm:col-span-1">
            <IndeterminateCheckbox
                label="Credit?"
                name="is_credit"
                defaultChecked
            />
          </div>
          <div className="col-span-3 sm:col-span-1">
            <IndeterminateCheckbox
                label="RFID?"
                name="is_rfid"
                defaultChecked
            />
          </div>
          <div className="col-span-3 sm:col-span-1">
            <IndeterminateCheckbox label="QR?" name="is_qr"/>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <IndeterminateCheckbox
                label="Tabs?"
                name="is_tab"
            />
          </div>
          <div className="col-span-3 sm:col-span-1">
            <IndeterminateCheckbox
                label="Tappit?"
                name="is_tappit"
            />
          </div>
        </div>
        <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
          <label className="col-span-6 block text-sm font-medium text-gray-700">
            Tip Configuration
          </label>
          <div className="col-span-6 sm:col-span-1">
            <IndeterminateCheckbox
              label="Tips Accepted?"
              name="is_tips"
              defaultChecked={tips}
              onChange={(checked) => setTips(checked as boolean)}
            />
          </div>
          {tips && (
            <div className="col-span-2 sm:col-span-1">
              <Input
                label="Tip Percentage 1"
                name="tip_percentage_1"
                defaultValue={15}
                required
              />
            </div>
          )}
          {tips && (
            <div className="col-span-2 sm:col-span-1">
              <Input
                label="Tip Percentage 2"
                name="tip_percentage_2"
                defaultValue={18}
                required
              />
            </div>
          )}
          {tips && (
            <div className="col-span-2 sm:col-span-1">
              <Input
                label="Tip Percentage 3"
                name="tip_percentage_3"
                defaultValue={20}
                required
              />
            </div>
          )}
        </div>
        <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
          <label className="col-span-6 block text-sm font-medium text-gray-700">
            Feature Configuration
          </label>
          <div className="col-span-3 sm:col-span-1">
            <IndeterminateCheckbox
              label="Custom Items Available?"
              name="is_custom_item"
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <IndeterminateCheckbox
              label="No Tax on Cash?"
              name="is_cash_not_taxed"
            />
          </div>
          <div
            className={discounts ? "col-span-3 sm:col-span-1" : "col-span-1"}
          >
            <IndeterminateCheckbox
              label="Discounts Available?"
              name="is_discount"
              defaultChecked={discounts}
              onChange={(checked) => {
                setDiscounts(checked as boolean);
                if (!checked) setIsDiscountProtected(false);
              }}
            />
          </div>
          {discounts && (
            <div className="col-span-3 sm:col-span-1">
              <IndeterminateCheckbox
                label="Discounts Password Protected?"
                name="is_discount_protected"
                defaultChecked={isDiscountProtected}
                onChange={(checked) =>
                  setIsDiscountProtected(checked as boolean)
                }
                required
              />
            </div>
          )}
          <div className="col-span-2 sm:col-span-1">
            <IndeterminateCheckbox
              label="Scan to Cart Mode?"
              name="is_cart_scanning"
            />
          </div>
          <div className={printing ? "col-span-3 sm:col-span-1" : "col-span-6"}>
            <IndeterminateCheckbox
              label="Ticket Printing Enabled?"
              name="is_printing"
              defaultChecked={printing}
              onChange={(checked) => {
                setPrinting(checked as boolean);
                if (!checked) setIsPrintingAuto(false);
                if (!checked) setIsPrintingCopies(0);
              }}
            />
          </div>
          {printing && (
            <div
              className={
                isPrintingAuto ? "col-span-3 sm:col-span-1" : "col-span-3"
              }
            >
              <IndeterminateCheckbox
                label="Auto Print Tickets on Order Completion?"
                name="is_printing_auto"
                defaultChecked={isPrintingAuto}
                onChange={(checked) => {
                  setIsPrintingAuto(checked as boolean);
                  if (!checked) setIsPrintingCopies(0);
                }}
              />
            </div>
          )}
          {isPrintingAuto && (
            <div className="col-span-6 sm:col-span-4">
              <Input
                label="Ticket Copies to Automatically Print?"
                name="is_printing_copies"
                defaultValue={isPrintingCopies}
                onChange={(value) =>
                  setIsPrintingCopies(typeof value === "number" ? value : 0)
                }
              />
            </div>
          )}
          {/*<div className="col-span-6">*/}
          {/*  <CustomSelect*/}
          {/*      title="Default Processing Mode"*/}
          {/*      name="processing_mode"*/}
          {/*      required*/}
          {/*      defaultValue="Dynamic Processing"*/}
          {/*      options={[*/}
          {/*        {value: "Dynamic Processing", label: "Dynamic Processing"},*/}
          {/*        {value: "Online Only (No Batches)", label: "Online Only (No Batches)"},*/}
          {/*        {value: "Offline Mode (No Real-Time Auth)", label: "Offline Mode (No Real-Time Auth)"},*/}
          {/*      ]}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Tax Type"
            name="tax_type"
            options={[
              { label: "Inclusive", value: "inclusive" },
              { label: "Exclusive", value: "exclusive" },
            ]}
          />
        </div>
        <div className="col-span-5 sm:col-span-3">
          <Input
            label="Category 1 "
            name="cat_1"
            maxLength={15}
            defaultValue={categoryData?.cat_1_hide ? "" : "Beer"}
            updateValue={true}
          />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Hide?"
            name="cat_1_hide"
            onChange={(data: any) => {
              setCategory({
                ...categoryData,
                cat_1_hide: !!data,
              });
            }}
          />
        </div>
        <>
          {selectedEvent && selectedVendor && (
            <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
              {items.map((item: Item) => (
                <div className="col-span-2 sm:col-span-1 ">
                  <IndeterminateCheckbox
                    label={`${item.name} (${usdFormat.format(
                      item.price / 100
                    )})`}
                    name="cat_1_items"
                    defaultChecked={categoryData.cat_1_items?.includes(
                      item?.id
                    )}
                    onChange={(e) =>
                      onCheckUnCheckItem(item.id, e, "cat_1_items", 1)
                    }
                  />
                </div>
              ))}
              <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-300 p-2 rounded">
                <label className="col-span-6 block text-sm font-medium text-gray-700">
                  Event Items
                </label>
                {itemsAllVendors.map((item: Item) => (
                  <div className="col-span-2 sm:col-span-1 ">
                    <IndeterminateCheckbox
                      label={`${item.name} (${usdFormat.format(
                        item.price / 100
                      )})`}
                      name="cat_1_items"
                      defaultChecked={categoryData.cat_1_items?.includes(
                        item?.id
                      )}
                      onChange={(e) =>
                        onCheckUnCheckItem(item.id, e, "cat_1_items", 1)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
        <div className="col-span-5 sm:col-span-3">
          <Input
            label="Category 2 "
            name="cat_2"
            maxLength={15}
            defaultValue={categoryData?.cat_2_hide ? "" : "Liquor"}
            updateValue={true}
          />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Hide?"
            name="cat_2_hide"
            onChange={(data: any) => {
              setCategory({
                ...categoryData,
                cat_2_hide: !!data,
              });
            }}
          />
        </div>
        <>
          {selectedEvent && selectedVendor && (
            <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
              {items.map((item: Item) => (
                <div className="col-span-2 sm:col-span-1 ">
                  <IndeterminateCheckbox
                    label={`${item.name} (${usdFormat.format(
                      item.price / 100
                    )})`}
                    name="cat_2_items"
                    defaultChecked={categoryData.cat_2_items?.includes(
                      item?.id
                    )}
                    onChange={(e) =>
                      onCheckUnCheckItem(item.id, e, "cat_2_items", 2)
                    }
                  />
                </div>
              ))}
              <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-300 p-2 rounded">
                <label className="col-span-6 block text-sm font-medium text-gray-700">
                  Event Items
                </label>
                {itemsAllVendors.map((item: Item) => (
                  <div className="col-span-2 sm:col-span-1 ">
                    <IndeterminateCheckbox
                      label={`${item.name} (${usdFormat.format(
                        item.price / 100
                      )})`}
                      name="cat_2_items"
                      defaultChecked={categoryData.cat_2_items?.includes(
                        item?.id
                      )}
                      onChange={(e) =>
                        onCheckUnCheckItem(item.id, e, "cat_2_items", 2)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
        <div className="col-span-5 sm:col-span-3">
          <Input
            label="Category 3 "
            name="cat_3"
            maxLength={15}
            defaultValue={categoryData?.cat_3_hide ? "" : "Wine"}
            updateValue={true}
          />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Hide?"
            name="cat_3_hide"
            onChange={(data: any) => {
              setCategory({
                ...categoryData,
                cat_3_hide: !!data,
              });
            }}
          />
        </div>
        <>
          {selectedEvent && selectedVendor && (
            <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
              {items.map((item: Item) => (
                <div className="col-span-2 sm:col-span-1 ">
                  <IndeterminateCheckbox
                    label={`${item.name} (${usdFormat.format(
                      item.price / 100
                    )})`}
                    name="cat_3_items"
                    defaultChecked={categoryData.cat_3_items?.includes(
                      item?.id
                    )}
                    onChange={(e) =>
                      onCheckUnCheckItem(item.id, e, "cat_3_items", 3)
                    }
                  />
                </div>
              ))}
              <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-300 p-2 rounded">
                <label className="col-span-6 block text-sm font-medium text-gray-700">
                  Event Items
                </label>
                {itemsAllVendors.map((item: Item) => (
                  <div className="col-span-2 sm:col-span-1 ">
                    <IndeterminateCheckbox
                      label={`${item.name} (${usdFormat.format(
                        item.price / 100
                      )})`}
                      name="cat_3_items"
                      defaultChecked={categoryData.cat_3_items?.includes(
                        item?.id
                      )}
                      onChange={(e) =>
                        onCheckUnCheckItem(item.id, e, "cat_3_items", 3)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
        <div className="col-span-5 sm:col-span-3">
          <Input
            label="Category 4 "
            name="cat_4"
            maxLength={15}
            defaultValue={categoryData?.cat_4_hide ? "" : "NonAL"}
            updateValue={true}
          />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Hide?"
            name="cat_4_hide"
            onChange={(data: any) => {
              setCategory({
                ...categoryData,
                cat_4_hide: !!data,
              });
            }}
          />
        </div>
        <>
          {selectedEvent && selectedVendor && (
            <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
              {items.map((item: Item) => (
                <div className="col-span-2 sm:col-span-1 ">
                  <IndeterminateCheckbox
                    label={`${item.name} (${usdFormat.format(
                      item.price / 100
                    )})`}
                    name="cat_4_items"
                    defaultChecked={categoryData.cat_4_items?.includes(
                      item?.id
                    )}
                    onChange={(e) =>
                      onCheckUnCheckItem(item.id, e, "cat_4_items", 4)
                    }
                  />
                </div>
              ))}
              <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-300 p-2 rounded">
                <label className="col-span-6 block text-sm font-medium text-gray-700">
                  Event Items
                </label>
                {itemsAllVendors.map((item: Item) => (
                  <div className="col-span-2 sm:col-span-1 ">
                    <IndeterminateCheckbox
                      label={`${item.name} (${usdFormat.format(
                        item.price / 100
                      )})`}
                      name="cat_4_items"
                      defaultChecked={categoryData.cat_4_items?.includes(
                        item?.id
                      )}
                      onChange={(e) =>
                        onCheckUnCheckItem(item.id, e, "cat_4_items", 4)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
        <div className="col-span-5 sm:col-span-3">
          <Input
            label="Category 5 "
            name="cat_5"
            maxLength={15}
            defaultValue={categoryData?.cat_5_hide ? "" : "Merchandise"}
            updateValue={true}
          />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Hide?"
            name="cat_5_hide"
            onChange={(data: any) => {
              setCategory({
                ...categoryData,
                cat_5_hide: !!data,
              });
            }}
          />
        </div>
        <>
          {selectedEvent && selectedVendor && (
            <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
              {items.map((item: Item) => (
                <div className="col-span-2 sm:col-span-1 ">
                  <IndeterminateCheckbox
                    label={`${item.name} (${usdFormat.format(
                      item.price / 100
                    )})`}
                    name="cat_5_items"
                    defaultChecked={categoryData.cat_5_items?.includes(
                      item?.id
                    )}
                    onChange={(e) =>
                      onCheckUnCheckItem(item.id, e, "cat_5_items", 5)
                    }
                  />
                </div>
              ))}
              <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-300 p-2 rounded">
                <label className="col-span-6 block text-sm font-medium text-gray-700">
                  Event Items
                </label>
                {itemsAllVendors.map((item: Item) => (
                  <div className="col-span-2 sm:col-span-1 ">
                    <IndeterminateCheckbox
                      label={`${item.name} (${usdFormat.format(
                        item.price / 100
                      )})`}
                      name="cat_5_items"
                      defaultChecked={categoryData.cat_5_items?.includes(
                        item?.id
                      )}
                      onChange={(e) =>
                        onCheckUnCheckItem(item.id, e, "cat_5_items", 5)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
        <div className="col-span-5 sm:col-span-3">
          <Input
            label="Category 6 "
            name="cat_6"
            maxLength={15}
            defaultValue={categoryData?.cat_6_hide ? "" : "Food"}
            updateValue={true}
          />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Hide?"
            name="cat_6_hide"
            onChange={(data: any) => {
              setCategory({
                ...categoryData,
                cat_6_hide: !!data,
              });
            }}
          />
        </div>
        <>
          {selectedEvent && selectedVendor && (
            <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
              {items.map((item: Item) => (
                <div className="col-span-2 sm:col-span-1 ">
                  <IndeterminateCheckbox
                    label={`${item.name} (${usdFormat.format(
                      item.price / 100
                    )})`}
                    name="cat_6_items"
                    defaultChecked={categoryData.cat_6_items?.includes(
                      item?.id
                    )}
                    onChange={(e) =>
                      onCheckUnCheckItem(item.id, e, "cat_6_items", 6)
                    }
                  />
                </div>
              ))}
              <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-300 p-2 rounded">
                <label className="col-span-6 block text-sm font-medium text-gray-700">
                  Event Items
                </label>
                {itemsAllVendors.map((item: Item) => (
                  <div className="col-span-2 sm:col-span-1 ">
                    <IndeterminateCheckbox
                      label={`${item.name} (${usdFormat.format(
                        item.price / 100
                      )})`}
                      name="cat_6_items"
                      defaultChecked={categoryData.cat_6_items?.includes(
                        item?.id
                      )}
                      onChange={(e) =>
                        onCheckUnCheckItem(item.id, e, "cat_6_items", 6)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
        <input
          type="hidden"
          value={categoryData.cat_1_items}
          name="cat_1_items"
        />
        <input
          type="hidden"
          value={categoryData.cat_2_items}
          name="cat_2_items"
        />
        <input
          type="hidden"
          value={categoryData.cat_3_items}
          name="cat_3_items"
        />
        <input
          type="hidden"
          value={categoryData.cat_4_items}
          name="cat_4_items"
        />
        <input
          type="hidden"
          value={categoryData.cat_5_items}
          name="cat_5_items"
        />
        <input
          type="hidden"
          value={categoryData.cat_6_items}
          name="cat_6_items"
        />
        <input type="hidden" value={JSON.stringify(itemIds)} name="all_items" />
      </FormLayout>
    </Form>
  );
};
