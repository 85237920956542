import {
  ActionFunctionArgs,
  Form,
  LoaderFunctionArgs,
  redirect,
  useFetcher,
  useLoaderData,
} from "react-router-dom";
import toast from "react-hot-toast";
import { EventsSelector } from "../../components/inputs/EventsSelector";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import { Input, TextArea } from "../../components/inputs/Input";
import PriceInput from "../../components/inputs/PriceInput";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { Item, ItemModifiers, TaxType, Categories } from "../../types/item";
import { GET_ITEM, GET_ITEM_CATEGORIES } from "./queries";
import { UPDATE_ITEM } from "./mutations";
import { TagsInput } from "../../components/inputs/TagsInput";
import { useEffect, useState } from "react";
import { usePermissions } from "../auth/api";
import { userStore } from "../../store/user";
import CustomSelect from "../../components/customSelect";
import {
  GET_LIST_EVENTS_BY_ORGANIZATION,
  GET_LIST_VENDORS_BY_ORGANIZATION,
} from "../../queries";
import { organizationStore } from "../../store/organization";
import { Vendor } from "../../types/vendor";
import { Event } from "../../types/event";
import { usdFormat } from "../../utils";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import { GET_ITEM_MODIFIER_BY_ORGANIZATION } from "../item-modifiers/queries";
import DeleteModal from "../../components/DeleteModal";

type OptionType = { id: string | number; name: string; disabled?: boolean };
const client = createApolloClient();
const { getState } = userStore;
const { getState: getOrganizationStore } = organizationStore;

export const itemEditLoader = async ({ params }: LoaderFunctionArgs) => {
  const organizationId = getOrganizationStore().organizationId;
  const user = getState().user;

  let eventWhere = {};
  let vendorWhere = {};

  if (user?.vendors) {
    vendorWhere = {
      ...vendorWhere,
      id: {
        _in: user?.vendors,
      },
    };
  }

  if (user?.events) {
    eventWhere = {
      ...eventWhere,
      id: {
        _in: user?.events,
      },
    };
  }

  if (organizationId) {
    vendorWhere = {
      ...vendorWhere,
      organization_id: {
        _eq: organizationId,
      },
    };

    eventWhere = {
      ...eventWhere,
      organization_id: { _eq: organizationId },
      is_active: { _eq: true },
    };
  }
  const { id } = params;
  const [
    { data: itemData },
    { data: vendorsData },
    { data: eventData },
    { data: itemCategoriesData },
  ] = await Promise.all([
    client.query({
      query: GET_ITEM,
      variables: { id },
    }),
    client.query({
      query: GET_LIST_VENDORS_BY_ORGANIZATION,
      variables: {
        where: vendorWhere,
      },
    }),
    client.query({
      query: GET_LIST_EVENTS_BY_ORGANIZATION,
      variables: {
        where: eventWhere,
      },
    }),
    client.query({
      query: GET_ITEM_CATEGORIES,
      variables: {
        where: organizationId
          ? {
              organization_id: {
                _in: [0, organizationId],
              },
              is_active: {
                _eq: true,
              },
              reference_type: {
                _eq: "item_category",
              },
            }
          : {},
      },
    }),
  ]);

  return {
    item: itemData.items_by_pk,
    vendors: vendorsData.vendors,
    events: eventData.events,
    itemCategories: itemCategoriesData.dashboard_reference,
  };
};

export const itemEditAction = async ({
  params,
  request,
}: ActionFunctionArgs) => {
  const permissions = getState().permissions;
  const user = getState().user;
  const { id } = params;
  const body = await request.formData();

  const priceString = body.get("price") as string;
  const price = parseFloat(priceString);
  const taxed = body.get("tax") === "true";
  const tagsString = body.get("tags") as string;
  const tags = tagsString.split(",").map((tag) => tag.trim());
  const taxPercentageString = body.get("tax_percentage") as string;
  const taxPercentage = (parseFloat(taxPercentageString) / 100).toFixed(8);
  const tokenPrice = body.get("token_price") || null;
  const tokenName = body.get("redeemable_token_name") || null;
  const modifiers1 = JSON.parse(body.get("itemModifiers1") as string);
  const modifiers2 = JSON.parse(body.get("itemModifiers2") as string);
  const modifiers3 = JSON.parse(body.get("itemModifiers3") as string);
  const modifiers4 = JSON.parse(body.get("itemModifiers4") as string);
  const modifiers5 = JSON.parse(body.get("itemModifiers5") as string);
  const modifiers6 = JSON.parse(body.get("itemModifiers6") as string);
  const modifierArray = [
    ...modifiers1,
    ...modifiers2,
    ...modifiers3,
    ...modifiers4,
    ...modifiers5,
    ...modifiers6,
  ];
  let bodyData: any = Object.fromEntries(body);
  console.log(bodyData.modifier_type);
  bodyData = { ...bodyData, vendor_id: bodyData.vendor_id || null };
  const redeemable_token_id = body.get("redeemable_token_name");
  let input;
  const unwantedKeys = [
    "itemModifiers1",
    "itemModifiers2",
    "itemModifiers3",
    "itemModifiers4",
    "itemModifiers5",
    "itemModifiers6",
    "sub_type1",
    "sub_type2",
    "sub_type3",
    "sub_type4",
    "sub_type5",
    "sub_type6",
    "modifier",
  ];
  let filteredBodyData = Object.keys(bodyData).reduce<Record<string, any>>(
    (acc, key) => {
      if (!unwantedKeys.includes(key)) {
        acc[key] = bodyData[key];
      }
      return acc;
    },
    {}
  );

  input = {
    ...filteredBodyData,
    tags,
    modifiers: modifierArray,
    redeemable_token_id: redeemable_token_id,
    price: Math.round(price * 100),
    short_name: body.get("name"),
    tax: taxed ? TaxType.TAXED : TaxType.NO_TAX,
    tax_percentage: taxed ? taxPercentage : 0,
    last_updated_by: user?.id,
    token_price: tokenPrice,
    redeemable_token_name: tokenName,
  };

  try {
    await client.mutate({
      mutation: UPDATE_ITEM,
      variables: {
        input,
        where: {
          id: {
            _eq: id,
          },
        },
      },
    });
    toast.success("Item updated");
    return redirect(`/items/list`);
  } catch (error) {
    toast.error("Failed to update item" + error);
  }
};

const SUB_TYPE_OPTIONS = {
  clothing: [
    { label: "Color", value: "color" },
    { label: "Gender", value: "gender" },
    { label: "Size", value: "size" },
    { label: "Artist", value: "artist" },
    { label: "Sub5", value: "sub5" },
    { label: "Sub6", value: "sub6" },
  ],
  drink: [
    { label: "Liquor", value: "liquor" },
    { label: "Mixer", value: "mixer" },
    { label: "Size", value: "size" },
  ],
  food: [
    { label: "Included", value: "included" },
    { label: "Extras", value: "extra" },
  ],
};

export const ItemEdit = () => {
  const { permissions } = usePermissions();
  const { item, vendors, events, itemCategories } = useLoaderData() as {
    item: Item;
    vendors: Vendor[];
    events: Event[];
    itemCategories: Categories[];
  };
  const distinctItemSubTypes = [
    ...new Set(item.modifiers.map((modifier) => modifier.sub_type)),
  ];
  const itemSubTypes = Array(6)
    .fill(null)
    .map((_, index) => distinctItemSubTypes[index] || null);
  const [variablePrice, setVariablePrice] = useState(item.is_variable_price);
  const [taxed, setTaxed] = useState(item.tax === TaxType.TAXED);
  const [price, setPrice] = useState(item.price / 100);
  const [token, setToken] = useState(item.token_price !== null);
  const [type, setType] = useState<string | null | undefined>(
    item.modifier_type
  );
  const [subType1, setSubType1] = useState(itemSubTypes[0]);
  const [subType2, setSubType2] = useState(itemSubTypes[1]);
  const [subType3, setSubType3] = useState(itemSubTypes[2]);
  const [subType4, setSubType4] = useState(itemSubTypes[3]);
  const [subType5, setSubType5] = useState(itemSubTypes[4]);
  const [subType6, setSubType6] = useState(itemSubTypes[5]);
  const [selectedSubTypes, setSelectedSubTypes] = useState<(string | number)[]>(
    []
  );
  const initialVisibleSubTypes = itemSubTypes.map(
    (subType) => subType !== null
  );
  initialVisibleSubTypes[0] = true;
  const [visibleSubTypes, setVisibleSubTypes] = useState(
    initialVisibleSubTypes
  );
  let firstNullIndex = itemSubTypes.indexOf(null);
  const initialVisibleAddButton = Array(6).fill(false);
  if (firstNullIndex !== -1) {
    initialVisibleAddButton[firstNullIndex - 1] = true;
  }
  const [visibleAddButton, setVisibleAddButton] = useState(
    initialVisibleAddButton
  );
  const [visibleRemoveButton, setVisibleRemoveButton] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const [allItemModifiers, setAllItemModifiers] = useState<ItemModifiers[]>([]);
  const [itemModifiers1, setItemModifiers1] = useState<ItemModifiers[]>([]);
  const [itemModifiers2, setItemModifiers2] = useState<ItemModifiers[]>([]);
  const [itemModifiers3, setItemModifiers3] = useState<ItemModifiers[]>([]);
  const [itemModifiers4, setItemModifiers4] = useState<ItemModifiers[]>([]);
  const [itemModifiers5, setItemModifiers5] = useState<ItemModifiers[]>([]);
  const [itemModifiers6, setItemModifiers6] = useState<ItemModifiers[]>([]);
  const currentOptions = type
    ? (SUB_TYPE_OPTIONS as { [key: string]: any })[type] || []
    : [];
  const groupedModifiers = itemSubTypes.reduce((acc, subType) => {
    if (subType) {
      acc[subType] = item.modifiers.filter(
        (modifier) => modifier.sub_type === subType
      );
    }
    return acc;
  }, {} as { [key: string]: ItemModifiers[] });
  const [checkedModifiers1, setCheckedModifiers1] = useState<any[]>(
    itemSubTypes[0] !== null ? groupedModifiers[itemSubTypes[0]] : []
  );
  const [checkedModifiers2, setCheckedModifiers2] = useState<any[]>(
    itemSubTypes[1] !== null ? groupedModifiers[itemSubTypes[1]] : []
  );
  const [checkedModifiers3, setCheckedModifiers3] = useState<any[]>(
    itemSubTypes[2] !== null ? groupedModifiers[itemSubTypes[2]] : []
  );
  const [checkedModifiers4, setCheckedModifiers4] = useState<any[]>(
    itemSubTypes[3] !== null ? groupedModifiers[itemSubTypes[3]] : []
  );
  const [checkedModifiers5, setCheckedModifiers5] = useState<any[]>(
    itemSubTypes[4] !== null ? groupedModifiers[itemSubTypes[4]] : []
  );
  const [checkedModifiers6, setCheckedModifiers6] = useState<any[]>(
    itemSubTypes[5] !== null ? groupedModifiers[itemSubTypes[5]] : []
  );
  const organizationId = getOrganizationStore().organizationId;

  const showNextSubType = (index: number) => {
    const updatedVisibilitySubType = [...visibleSubTypes];
    const updatedVisibilityAddButton = [...visibleAddButton];
    const updatedVisibilityRemoveButton = [...visibleRemoveButton];
    updatedVisibilityAddButton[index] = false;
    updatedVisibilitySubType[index + 1] = true;
    updatedVisibilityAddButton[index + 1] = true;
    updatedVisibilityRemoveButton[index + 1] = true;
    setVisibleSubTypes(updatedVisibilitySubType);
    setVisibleAddButton(updatedVisibilityAddButton);
    setVisibleRemoveButton(updatedVisibilityRemoveButton);
  };

  const removePreviousSubType = (index: number) => {
    const updatedVisibilitySubType = [...visibleSubTypes];
    const updatedVisibilityAddButton = [...visibleAddButton];
    const updatedVisibilityRemoveButton = [...visibleRemoveButton];
    updatedVisibilityAddButton[index] = true;
    updatedVisibilitySubType[index] = false;
    updatedVisibilityAddButton[index - 1] = true;
    updatedVisibilityRemoveButton[index] = false;
    setVisibleSubTypes(updatedVisibilitySubType);
    setVisibleAddButton(updatedVisibilityAddButton);
    setVisibleRemoveButton(updatedVisibilityRemoveButton);
  };

  const addSelectedSubType = (subType: string | number) => {
    setSelectedSubTypes([...selectedSubTypes, subType]);
  };
  const removeSelectedSubType = (subType: string | number) => {
    setSelectedSubTypes((prevState) =>
      prevState.filter((type) => type !== subType)
    );
  };
  const subTypeSelected = (v: string | number) => {
    addSelectedSubType(v);
  };
  useEffect(() => {
    const fetchAllModifiers = async () => {
      const { data } = await client.query({
        query: GET_ITEM_MODIFIER_BY_ORGANIZATION,
        variables: {
          where: organizationId
            ? { organization_id: { _eq: organizationId } }
            : {},
        },
      });
      setAllItemModifiers(data.item_modifiers);
    };
    fetchAllModifiers();
  }, [organizationId]);

  useEffect(() => {
    if (type && subType1) {
      const filteredModifiers1 = allItemModifiers.filter(
        (mod) => mod.type === type && mod.sub_type === subType1
      );
      setItemModifiers1(filteredModifiers1);
    }
  }, [type, subType1, allItemModifiers]);

  useEffect(() => {
    if (type && subType2) {
      const filteredModifiers2 = allItemModifiers.filter(
        (mod) => mod.type === type && mod.sub_type === subType2
      );
      setItemModifiers2(filteredModifiers2);
    }
  }, [type, subType2, allItemModifiers]);

  useEffect(() => {
    if (type && subType3) {
      const filteredModifiers3 = allItemModifiers.filter(
        (mod) => mod.type === type && mod.sub_type === subType3
      );
      setItemModifiers3(filteredModifiers3);
    }
  }, [type, subType3, allItemModifiers]);

  useEffect(() => {
    if (type && subType4) {
      const filteredModifiers4 = allItemModifiers.filter(
        (mod) => mod.type === type && mod.sub_type === subType4
      );
      setItemModifiers4(filteredModifiers4);
    }
  }, [type, subType4, allItemModifiers]);

  useEffect(() => {
    if (type && subType5) {
      const filteredModifiers5 = allItemModifiers.filter(
        (mod) => mod.type === type && mod.sub_type === subType5
      );
      setItemModifiers5(filteredModifiers5);
    }
  }, [type, subType5, allItemModifiers]);

  useEffect(() => {
    if (type && subType6) {
      const filteredModifiers6 = allItemModifiers.filter(
        (mod) => mod.type === type && mod.sub_type === subType6
      );
      setItemModifiers6(filteredModifiers6);
    }
  }, [type, subType6, allItemModifiers]);
  const { deletePermission } = usePermissions("items");
  const fetcher = useFetcher();

  const handlePriceChange = (value: number) => {
    setPrice(value);
  };
  return (
    <Form
      method="put"
      action={`/items/${item.id}/edit`}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      {deletePermission && (
        <div className="flex">
          <div className="ml-auto py-2">
            <DeleteModal
              onConfirm={() => {
                fetcher.submit(
                  {},
                  {
                    method: "delete",
                    action: `/items/${item.id}/delete`,
                  }
                );
              }}
            >
              {({ setOpen }) => (
                <SecondaryButton onClick={() => setOpen(true)}>
                  Delete
                </SecondaryButton>
              )}
            </DeleteModal>
          </div>
        </div>
      )}
      <FormLayout>
        <div className="col-span-5 sm:col-span-3">
          <Input label="Item Name" name="name" defaultValue={item.name} />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Is Active?"
            name="is_active"
            defaultChecked={item.is_active}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Event"
            name="event_id"
            required
            defaultValue={item.event_id}
            options={[
              ...(events?.map((e) => ({
                label: e.name,
                value: e.id,
              })) || []),
            ]}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Vendor"
            name="vendor_id"
            required
            options={[
              {
                value: 0,
                label: "All Vendors",
              },
              ...(vendors?.map((v) => ({
                label: v.name,
                value: v.id,
              })) || []),
            ]}
            defaultValue={item.vendor_id}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Item Category"
            name="category"
            required
            defaultValue={item.category}
            options={[
              ...(itemCategories?.map((v) => ({
                label: v.reference_label,
                value: v.reference_value,
              })) || []),
            ]}
          />
        </div>
        {/*<div className="col-span-6">*/}
        {/*  <Input*/}
        {/*    label="Receipt Name"*/}
        {/*    name="short_name"*/}
        {/*    defaultValue={item.short_name}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="col-span-6">
          <TextArea
            label="Description"
            name="description"
            defaultValue={item.description}
          />
        </div>
        <div className="col-span-6">
          <Input label="UPC" name="upc" defaultValue={item.upc} />
        </div>
        <div className="col-span-6">
          <TagsInput
            label="Optional Tags"
            name="tags"
            placeholder="Add a Tag (press 'enter' to add)"
            defaultValue={item.tags}
          />
        </div>
        <div className="col-span-3">
          <Input
            label="GL Account"
            name="gl_account_name"
            defaultValue={item.gl_account_name}
          />
        </div>
        <div className="col-span-3">
          <Input
            label="GL Account Number"
            name="gl_account_number"
            defaultValue={item.gl_account_number}
          />
        </div>
        {!variablePrice && (
          <div className="col-span-6 sm:col-span-2">
            <PriceInput
              label="Price"
              name="price"
              value={price}
              onChange={(value) => handlePriceChange(value ? value : 0)}
            />
          </div>
        )}
        {variablePrice && <input type="hidden" value={"0"} name="price" />}
        <div className="col-span-1 sm:col-span-1">
          <IndeterminateCheckbox
            label="Custom Price?"
            name="is_variable_price"
            defaultChecked={variablePrice}
            onChange={(checked) => setVariablePrice(checked as boolean)}
          />
        </div>
        <div className="col-span-1 sm:col-span-1">
          <IndeterminateCheckbox
            label="Exclusive tax?"
            name="tax"
            defaultChecked={taxed}
            onChange={(checked) => setTaxed(checked as boolean)}
          />
        </div>
        {taxed && (
          <>
            <div className="col-span-5 sm:col-span-2">
              <Input
                label="Tax % ( 7.5 = 7.5% )"
                name="tax_percentage"
                defaultValue={(item.tax_percentage * 100).toFixed(3)}
              />
            </div>
          </>
        )}
        <div className="col-span-6">
          <IndeterminateCheckbox
            label="Token Eligible"
            defaultChecked={token}
            onChange={(checked) => setToken(checked as boolean)}
          />
        </div>
        {token && (
          <>
            <div className="col-span-6 sm:col-span-6">
              <Input
                label="Token Redemption Ratio (example: 1, 2, 3)"
                name="token_price"
                defaultValue={item.token_price}
                required
              />
            </div>
            <div className="col-span-6 sm:col-span-6">
              <Input
                label="Token Name (example: VIP TOKEN)"
                name="redeemable_token_name"
                defaultValue={item.redeemable_token_name}
                required
              />
            </div>
          </>
        )}
        <div className="col-span-6">
          <CustomSelect
            title="Add Modifiers?"
            name="modifier_type"
            defaultValue={item.modifier_type}
            onChange={(v) => {
              setType(v);
              showNextSubType(-1);
              setSubType1(null);
              setSubType2(null);
              setSubType3(null);
              setSubType4(null);
              setSubType5(null);
              setSubType6(null);
              setItemModifiers1([]);
              setItemModifiers2([]);
              setItemModifiers3([]);
              setItemModifiers4([]);
              setItemModifiers5([]);
              setItemModifiers6([]);
              setCheckedModifiers1([]);
              setCheckedModifiers2([]);
              setCheckedModifiers3([]);
              setCheckedModifiers4([]);
              setCheckedModifiers5([]);
              setCheckedModifiers6([]);
            }}
            options={[
              { label: "NONE", value: "" },
              { label: "Clothing", value: "clothing" },
              { label: "Drink", value: "drink" },
              { label: "Food", value: "food" },
            ]}
          />
        </div>
        {type && visibleSubTypes[0] && (
          <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
            <div className="col-span-6">
              <CustomSelect
                title="Sub-Type 1"
                name="sub_type1"
                defaultValue={subType1}
                onChange={(v) => {
                  setSubType1(v);
                  setCheckedModifiers1([]);
                  subTypeSelected(v);
                  if (subType1) {
                    removeSelectedSubType(subType1);
                  }
                }}
                options={currentOptions}
              />
            </div>
            {itemModifiers1.map((itemModifier, index) => (
              <div className="col-span-1">
                <IndeterminateCheckbox
                  label={`${itemModifier.name} (${usdFormat.format(
                    itemModifier.additional_price / 100
                  )})`}
                  name="modifier"
                  defaultChecked={checkedModifiers1.some(
                    (modifier) => modifier.id === itemModifier.id
                  )}
                  onChange={(checked) => {
                    if (checked) {
                      setCheckedModifiers1((prevState) => [
                        ...prevState,
                        {
                          id: itemModifier.id,
                          name: itemModifier.name,
                          additional_price: itemModifier.additional_price,
                          sub_type: subType1,
                        },
                      ]);
                    } else {
                      setCheckedModifiers1((prevState) =>
                        prevState.filter(
                          (modifier) => modifier.id !== itemModifier.id
                        )
                      );
                    }
                  }}
                />
              </div>
            ))}
          </div>
        )}
        {type && visibleRemoveButton[0] && visibleAddButton[0] && (
          <div className="col-span-3 sm:col-span-1">
            <PrimaryButton
              onClick={() => {
                setSubType1(null);
                removePreviousSubType(0);
                setItemModifiers1([]);
                setCheckedModifiers1([]);
                setType(null);
                if (subType1) {
                  removeSelectedSubType(subType1);
                }
              }}
            >
              Remove Sub-Type 1
            </PrimaryButton>
          </div>
        )}
        {subType1 && visibleAddButton[0] && (
          <div className="col-span-3 sm:col-span-1">
            <PrimaryButton onClick={() => showNextSubType(0)}>
              Add Sub-Type 2
            </PrimaryButton>
          </div>
        )}
        {type && subType1 && visibleSubTypes[1] && (
          <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
            <div className="col-span-6">
              <CustomSelect
                title="Sub-Type 2"
                name="sub_type2"
                defaultValue={subType2}
                onChange={(v) => {
                  setSubType2(v);
                  setCheckedModifiers2([]);
                  subTypeSelected(v);
                  if (subType2) {
                    removeSelectedSubType(subType2);
                  }
                }}
                options={currentOptions}
              />
            </div>
            {itemModifiers2.map((itemModifier, index) => (
              <div className="col-span-1">
                <IndeterminateCheckbox
                  label={`${itemModifier.name} (${usdFormat.format(
                    itemModifier.additional_price / 100
                  )})`}
                  name="modifier"
                  defaultChecked={checkedModifiers2.some(
                    (modifier) => modifier.id === itemModifier.id
                  )}
                  onChange={(checked) => {
                    if (checked) {
                      setCheckedModifiers2((prevState) => [
                        ...prevState,
                        {
                          id: itemModifier.id,
                          name: itemModifier.name,
                          additional_price: itemModifier.additional_price,
                          sub_type: subType2,
                        },
                      ]);
                    } else {
                      setCheckedModifiers2((prevState) =>
                        prevState.filter(
                          (modifier) => modifier.id !== itemModifier.id
                        )
                      );
                    }
                  }}
                />
              </div>
            ))}
          </div>
        )}
        {visibleAddButton[1] && visibleRemoveButton[1] && (
          <div className="col-span-3 sm:col-span-1">
            <PrimaryButton
              onClick={() => {
                setSubType2(null);
                removePreviousSubType(1);
                setItemModifiers2([]);
                setCheckedModifiers2([]);
                if (subType2) {
                  removeSelectedSubType(subType2);
                }
              }}
            >
              Remove Sub-Type 2
            </PrimaryButton>
          </div>
        )}
        {subType2 && visibleAddButton[1] && (
          <div className="col-span-3 sm:col-span-1">
            <PrimaryButton onClick={() => showNextSubType(1)}>
              + Add Sub-Type 3
            </PrimaryButton>
          </div>
        )}

        {type && subType2 && visibleSubTypes[2] && (
          <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
            <div className="col-span-6">
              <CustomSelect
                title="Sub-Type 3"
                name="sub_type3"
                defaultValue={subType3}
                onChange={(v) => {
                  setSubType3(v);
                  setCheckedModifiers3([]);
                  subTypeSelected(v);
                  if (subType3) {
                    removeSelectedSubType(subType3);
                  }
                }}
                options={currentOptions}
              />
            </div>
            {itemModifiers3.map((itemModifier, index) => (
              <div className="col-span-1">
                <IndeterminateCheckbox
                  label={`${itemModifier.name} (${usdFormat.format(
                    itemModifier.additional_price / 100
                  )})`}
                  name="modifier"
                  defaultChecked={checkedModifiers3.some(
                    (modifier) => modifier.id === itemModifier.id
                  )}
                  onChange={(checked) => {
                    if (checked) {
                      setCheckedModifiers3((prevState) => [
                        ...prevState,
                        {
                          id: itemModifier.id,
                          name: itemModifier.name,
                          additional_price: itemModifier.additional_price,
                          sub_type: subType1, // Assuming subType1 is available in the scope
                        },
                      ]);
                    } else {
                      setCheckedModifiers3((prevState) =>
                        prevState.filter(
                          (modifier) => modifier.id !== itemModifier.id
                        )
                      );
                    }
                  }}
                />
              </div>
            ))}
          </div>
        )}
        {visibleAddButton[2] && visibleRemoveButton[2] && (
          <div className="col-span-3 sm:col-span-1">
            <PrimaryButton
              onClick={() => {
                setSubType3(null);
                removePreviousSubType(2);
                setItemModifiers3([]);
                setCheckedModifiers3([]);
                if (subType3) {
                  removeSelectedSubType(subType3);
                }
              }}
            >
              Remove Sub-Type 3
            </PrimaryButton>
          </div>
        )}
        {type && subType3 && visibleAddButton[2] && (
          <div className="col-span-3 sm:col-span-1">
            <PrimaryButton onClick={() => showNextSubType(2)}>
              + Add Sub-Type 4
            </PrimaryButton>
          </div>
        )}
        {type && subType3 && visibleSubTypes[3] && (
          <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
            <div className="col-span-6">
              <CustomSelect
                title="Sub-Type 4"
                name="sub_type4"
                defaultValue={subType4}
                onChange={(v) => {
                  setSubType4(v);
                  setCheckedModifiers4([]);
                  subTypeSelected(v);
                  if (subType4) {
                    removeSelectedSubType(subType4);
                  }
                }}
                options={currentOptions}
              />
            </div>
            {itemModifiers4.map((itemModifier, index) => (
              <div className="col-span-1">
                <IndeterminateCheckbox
                  label={`${itemModifier.name} (${usdFormat.format(
                    itemModifier.additional_price / 100
                  )})`}
                  name="modifier"
                  defaultChecked={checkedModifiers4.some(
                    (modifier) => modifier.id === itemModifier.id
                  )}
                  onChange={(checked) => {
                    if (checked) {
                      setCheckedModifiers4((prevState) => [
                        ...prevState,
                        {
                          id: itemModifier.id,
                          name: itemModifier.name,
                          additional_price: itemModifier.additional_price,
                          sub_type: subType4,
                        },
                      ]);
                    } else {
                      setCheckedModifiers4((prevState) =>
                        prevState.filter(
                          (modifier) => modifier.id !== itemModifier.id
                        )
                      );
                    }
                  }}
                />
              </div>
            ))}
          </div>
        )}
        {visibleAddButton[3] && visibleRemoveButton[3] && (
          <div className="col-span-3 sm:col-span-1">
            <PrimaryButton
              onClick={() => {
                setSubType4(null);
                removePreviousSubType(3);
                setItemModifiers4([]);
                setCheckedModifiers4([]);
                if (subType4) {
                  removeSelectedSubType(subType4);
                }
              }}
            >
              Remove Sub-Type 4
            </PrimaryButton>
          </div>
        )}
        {type && subType4 && visibleAddButton[3] && (
          <div className="col-span-3 sm:col-span-1">
            <PrimaryButton onClick={() => showNextSubType(3)}>
              + Add Sub-Type 5
            </PrimaryButton>
          </div>
        )}
        {type && subType4 && visibleSubTypes[4] && (
          <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
            <div className="col-span-6">
              <CustomSelect
                title="Sub-Type 5"
                name="sub_type5"
                defaultValue={subType5}
                onChange={(v) => {
                  setSubType5(v);
                  setCheckedModifiers5([]);
                  subTypeSelected(v);
                  if (subType5) {
                    removeSelectedSubType(subType5);
                  }
                }}
                options={currentOptions}
              />
            </div>
            {itemModifiers5.map((itemModifier, index) => (
              <div className="col-span-1">
                <IndeterminateCheckbox
                  label={`${itemModifier.name} (${usdFormat.format(
                    itemModifier.additional_price / 100
                  )})`}
                  name="modifier"
                  defaultChecked={checkedModifiers5.some(
                    (modifier) => modifier.id === itemModifier.id
                  )}
                  onChange={(checked) => {
                    if (checked) {
                      setCheckedModifiers5((prevState) => [
                        ...prevState,
                        {
                          id: itemModifier.id,
                          name: itemModifier.name,
                          additional_price: itemModifier.additional_price,
                          sub_type: subType5,
                        },
                      ]);
                    } else {
                      setCheckedModifiers5((prevState) =>
                        prevState.filter(
                          (modifier) => modifier.id !== itemModifier.id
                        )
                      );
                    }
                  }}
                />
              </div>
            ))}
          </div>
        )}
        {visibleAddButton[4] && visibleRemoveButton[4] && (
          <div className="col-span-3 sm:col-span-1">
            <PrimaryButton
              onClick={() => {
                setSubType5(null);
                removePreviousSubType(4);
                setItemModifiers5([]);
                setCheckedModifiers5([]);
                if (subType5) {
                  removeSelectedSubType(subType5);
                }
              }}
            >
              Remove Sub-Type 5
            </PrimaryButton>
          </div>
        )}
        {type && subType5 && visibleAddButton[4] && (
          <div className="col-span-3 sm:col-span-1">
            <PrimaryButton onClick={() => showNextSubType(4)}>
              + Add Sub-Type 6
            </PrimaryButton>
          </div>
        )}
        {type && subType5 && visibleSubTypes[5] && (
          <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
            <div className="col-span-6">
              <CustomSelect
                title="Sub-Type 6"
                name="sub_type6"
                defaultValue={subType6}
                onChange={(v) => {
                  setSubType6(v);
                  setCheckedModifiers6([]);
                  subTypeSelected(v);
                  if (subType6) {
                    removeSelectedSubType(subType6);
                  }
                }}
                options={currentOptions}
              />
            </div>
            {itemModifiers6.map((itemModifier, index) => (
              <div className="col-span-1">
                <IndeterminateCheckbox
                  label={`${itemModifier.name} (${usdFormat.format(
                    itemModifier.additional_price / 100
                  )})`}
                  name="modifier"
                  defaultChecked={checkedModifiers6.some(
                    (modifier) => modifier.id === itemModifier.id
                  )}
                  onChange={(checked) => {
                    if (checked) {
                      setCheckedModifiers6((prevState) => [
                        ...prevState,
                        {
                          id: itemModifier.id,
                          name: itemModifier.name,
                          additional_price: itemModifier.additional_price,
                          sub_type: subType6,
                        },
                      ]);
                    } else {
                      setCheckedModifiers6((prevState) =>
                        prevState.filter(
                          (modifier) => modifier.id !== itemModifier.id
                        )
                      );
                    }
                  }}
                />
              </div>
            ))}
          </div>
        )}
        {visibleAddButton[5] && visibleRemoveButton[5] && (
          <div className="col-span-3 sm:col-span-1">
            <PrimaryButton
              onClick={() => {
                setSubType6(null);
                removePreviousSubType(5);
                setItemModifiers6([]);
                setCheckedModifiers6([]);
                if (subType6) {
                  removeSelectedSubType(subType6);
                }
              }}
            >
              Remove Sub-Type 6
            </PrimaryButton>
          </div>
        )}
        <input
          type="hidden"
          value={JSON.stringify(checkedModifiers1)}
          name="itemModifiers1"
        />
        <input
          type="hidden"
          value={JSON.stringify(checkedModifiers2)}
          name="itemModifiers2"
        />
        <input
          type="hidden"
          value={JSON.stringify(checkedModifiers3)}
          name="itemModifiers3"
        />
        <input
          type="hidden"
          value={JSON.stringify(checkedModifiers4)}
          name="itemModifiers4"
        />
        <input
          type="hidden"
          value={JSON.stringify(checkedModifiers5)}
          name="itemModifiers5"
        />
        <input
          type="hidden"
          value={JSON.stringify(checkedModifiers6)}
          name="itemModifiers6"
        />
      </FormLayout>
    </Form>
  );
};
