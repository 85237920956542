import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useRevalidator } from "react-router-dom";
import { Input } from "../../../components/inputs/Input";
import CustomSelect from "../../../components/customSelect";
import {
  organizationStore,
  useOrganizationStore,
} from "../../../store/organization";
import {
  renderLocationOptions,
  renderUsernameOptions,
  renderVendorOptions,
} from "../../reports/utils";
import { Vendor } from "../../../types/vendor";
import { VENDORS_LIST_QUERY } from "../../vendors/queries";
import { createApolloClient } from "../../../providers/ApolloClientFactory";
import { PrimaryButton } from "../../../components/Button";
import { userStore } from "../../../store/user";
import { eventStore } from "../../../store/event";
import { GET_LOCATIONS_REPORT_FILTER} from "../../locations/queries";
const { getState } = userStore;
const { getState: getOrganizationState } = organizationStore;
const { getState: getEventState } = eventStore;
function OrderFilter() {
  const revalidator = useRevalidator();
  const navigate = useNavigate();
  const client = createApolloClient();
  const organizationId = getOrganizationState().organizationId;
  const { eventId } = getEventState();
  const user = getState().user;
  const permissions = getState().permissions;
  const [locations, setLocations] = useState<Location[]>([]);
  const searchLocation = useLocation();
  const searchParams = new URLSearchParams(searchLocation.search);

  const vendorId = searchParams.get("vendor") ?? "";
  const locationId = searchParams.get("location") ?? "";
  const username = searchParams.get("username") ?? "";
  const last4ofCC = searchParams.get("last4ofCC") ?? "";
  const cardType = searchParams.get("cardType") ?? "";
  const transactionAmount = searchParams.get("transactionAmount") ?? "";
  const paymentRefId = searchParams.get("last4ofCC") ?? "";
  const mxRefId = searchParams.get("mxRefId") ?? "";
  const cashlessAssetId = searchParams.get("cashlessAssetId") ?? "";
  const firstName = searchParams.get("firstName") ?? "";
  const lastName = searchParams.get("lastName") ?? "";
  const email = searchParams.get("email") ?? "";
  const phoneNumber = searchParams.get("phoneNumber") ?? "";

  const users = useOrganizationStore((state) => state.clerks);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [resetKey, setResetKey] = useState(0);
  const cardTypeOptions = [
    { value: "American Express", label: "American Express" },
    {
      value: "Diner's Club International",
      label: "Diner's Club International",
    },
    { value: "Discover", label: "Discover" },
    { value: "Maestro", label: "Maestro" },
    { value: "MasterCard", label: "MasterCard" },
    { value: "UnionPay", label: "UnionPay" },
    { value: "Visa", label: "Visa" },
  ];
  const [formFields, setFormFields] = useState({
    vendor: vendorId,
    location: locationId,
    username: username,
    last4ofCC: last4ofCC,
    cardType: cardType,
    transactionAmount: transactionAmount,
    paymentRefId: paymentRefId,
    mxRefId: mxRefId,
    cashlessAssetId: cashlessAssetId,
    firstName: firstName,
    lastName: lastName,
    phoneNumber: phoneNumber,
    email: email,
  });

  const handleFieldChange = (e: string | number, fieldName: string): void => {
    let value: string | number;

    if (typeof e === "string") {
      value = e.toLowerCase();
      if (fieldName === "phoneNumber") {
        value = value.replace(/-/g, "");
      }
    } else {
      value = e;
    }

    setFormFields({
      ...formFields,
      [fieldName]: value,
    });
  };

  const executeSearch = () => {
    if (formFields.last4ofCC && formFields.last4ofCC.length !== 4) {
      alert(
        'The "Last 4 Digits of Credit Card" field must contain exactly 4 digits.'
      );
      return;
    }

    const modifiedFormFields = { ...formFields };

    if (modifiedFormFields.transactionAmount !== "") {
      const floatAmount = parseFloat(modifiedFormFields.transactionAmount);
      if (!isNaN(floatAmount)) {
        modifiedFormFields.transactionAmount = Math.round(
          floatAmount * 100
        ).toString();
      } else {
        modifiedFormFields.transactionAmount = "";
      }
    }
    for (const [key, value] of Object.entries(modifiedFormFields)) {
      searchParams.set(key, value.toString());
    }
    navigate({
      pathname: "/orders/list",
      search: searchParams.toString(),
    });

    revalidator.revalidate();
  };
  const clearFilters = () => {
    setFormFields({
      vendor: "",
      location: "",
      username: "",
      last4ofCC: "",
      cardType: "",
      transactionAmount: "",
      paymentRefId: "",
      mxRefId: "",
      cashlessAssetId: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
    });

    searchParams.delete("vendor");
    searchParams.delete("location");
    searchParams.delete("username");
    searchParams.delete("last4ofCC");
    searchParams.delete("cardType");
    searchParams.delete("transactionAmount");
    searchParams.delete("paymentRefId");
    searchParams.delete("mxRefId");
    searchParams.delete("cashlessAssetId");
    searchParams.delete("firstName");
    searchParams.delete("lastName");
    searchParams.delete("phoneNumber");
    searchParams.delete("email");

    setResetKey((prevKey) => prevKey + 1);

    navigate({
      pathname: "/orders/list",
      search: searchParams.toString(),
    });
    revalidator.revalidate();
  };
  const fetchVendor = async () => {
    let where: any = organizationId
        ? {
          organization_id: {
            _eq: organizationId,
          },
        }
        : {};
    let locationWhere: any = organizationId
        ? {
          event: {organization_id: {
              _eq: organizationId,
            }},
        }
        : {};
    if (eventId) {
      locationWhere = {
        ...locationWhere,
        event_id: {
          _eq: eventId,
        },
      };
    }
    if (user?.vendors) {
      where = {
        ...where,
        id: {
          _in: user?.vendors,
        },
      };
    }
    if (user?.vendors) {
      locationWhere = {
        vendor_id: {
          _in: user?.vendors,
        },
      };
    }

    if (user?.events) {
      locationWhere = {
        event_id: {
          _in: user?.events,
        },
      };
    }
    const [{ data: vendorData }, {data: locationData}] = await Promise.all ([
      client.query({
        query: VENDORS_LIST_QUERY,
        variables: {
          where,
        },
      }),
      client.query({
        query: GET_LOCATIONS_REPORT_FILTER,
        variables: {
          locationWhere,
        },
      }),
    ]);
    setVendors(vendorData?.vendors || []);
    setLocations(locationData?.locations || []);
  };
  useEffect(() => {
    fetchVendor();
  }, [organizationId]);

  return (
    <div className="px-4 grid grid-cols-6 gap-3">
      {!user?.vendor_id && (
        <div className="col-span-6 sm:col-span-2">
          <CustomSelect
            name="vendor"
            title="Vendor"
            key={resetKey}
            onChange={(e) => handleFieldChange(e, "vendor")}
            options={renderVendorOptions(vendors ?? [])}
          />
        </div>
      )}
      <div className="col-span-6 sm:col-span-2">
        <CustomSelect
          name="location"
          title="Location"
          key={resetKey}
          onChange={(e) => handleFieldChange(e, "location")}
          options={renderLocationOptions(locations ?? [])}
        />
      </div>
      {!user?.vendor_id && (
        <div className="col-span-6 sm:col-span-2">
          <CustomSelect
            name="username"
            title="Username"
            key={resetKey}
            onChange={(e) => handleFieldChange(e, "username")}
            options={renderUsernameOptions(users ?? [])}
          />
        </div>
      )}
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="Last 4 Digits of Credit Card"
          name="last4ofCC"
          value={formFields.last4ofCC}
          placeholder="Last 4 Digits of Credit Card"
          onChange={(e) => handleFieldChange(e ?? "", "last4ofCC")}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <CustomSelect
          name="cardType"
          title="Card Type"
          key={resetKey}
          onChange={(e) => handleFieldChange(e, "cardType")}
          options={cardTypeOptions}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="Transaction Amount"
          name="transactionAmount"
          placeholder="$0.00"
          value={formFields.transactionAmount}
          onChange={(e) => handleFieldChange(e ?? "", "transactionAmount")}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="Ronin Reference ID"
          name="paymentRefId"
          placeholder="Ronin Reference ID"
          value={formFields.paymentRefId}
          onChange={(e) => handleFieldChange(e ?? "", "paymentRefId")}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="Processor Reference ID"
          name="mxRefId"
          placeholder="Processor Reference ID"
          value={formFields.mxRefId}
          onChange={(e) => handleFieldChange(e ?? "", "mxRefId")}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="Cashless Asset ID"
          name="cashlessAssetId"
          placeholder="Cashless Asset ID"
          value={formFields.cashlessAssetId}
          onChange={(e) => handleFieldChange(e ?? "", "cashlessAssetId")}
        />
      </div>
      {/*<div className="col-span-1">*/}
      {/*  <Input*/}
      {/*    label="Attendee First Name"*/}
      {/*    name="firstName"*/}
      {/*    placeholder="First Name"*/}
      {/*    value={formFields.firstName}*/}
      {/*    onChange={(e) => handleFieldChange(e ?? '', 'firstName')}*/}
      {/*  />*/}
      {/*</div>*/}
      {/*  <div className="col-span-1">*/}
      {/*      <Input*/}
      {/*          label="Attendee Last Name"*/}
      {/*          name="lastName"*/}
      {/*          placeholder="Last Name"*/}
      {/*          value={formFields.lastName}*/}
      {/*          onChange={(e) => handleFieldChange(e ?? '', 'lastName')}*/}
      {/*      />*/}
      {/*  </div>*/}
      {/*  <div className="col-span-2">*/}
      {/*      <Input*/}
      {/*          label="Email"*/}
      {/*          name="email"*/}
      {/*          placeholder="Email"*/}
      {/*          value={formFields.email}*/}
      {/*          onChange={(e) => handleFieldChange(e ?? '', 'email')}*/}
      {/*      />*/}
      {/*  </div>*/}
      {/*<div className="col-span-2">*/}
      {/*  <Input*/}
      {/*    label="Phone Number"*/}
      {/*    name="phoneNumber"*/}
      {/*    placeholder="Phone Number"*/}
      {/*    onChange={(e) => handleFieldChange(e ?? '', 'phoneNumber')}*/}
      {/*  />*/}
      {/*</div>*/}
      <div className="col-span-6 text-right">
        <PrimaryButton onClick={clearFilters}>Clear Filters</PrimaryButton>
        <PrimaryButton onClick={executeSearch}>Search</PrimaryButton>
      </div>
    </div>
  );
}

export default OrderFilter;
