import toast from "react-hot-toast";
import { CREATE_DISCOUNT } from "./mutations";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { Input } from "../../components/inputs/Input";
import { ActionFunctionArgs, Form, redirect } from "react-router-dom";
import { FormLayout } from "../../layout/FormLayout";
import RadioGroup from "../../components/inputs/RadioGroup";
import PercentageInput from "../../components/inputs/PercentageInput";
import PriceInput from "../../components/inputs/PriceInput";
import { useState } from "react";
import CustomSelect from "../../components/customSelect";
import { useOrganizationStore } from "../../store/organization"; // <-- Added
import { userStore } from "../../store/user";
const { getState: getUserStore } = userStore;

const client = createApolloClient();

export const discountCreateAction = async ({ request }: ActionFunctionArgs) => {
  const data = await request.formData();
  const formData = Object.fromEntries(data);
  const user = getUserStore().user;
  if (formData.discount_type === "amount") {
    const priceString = data.get("amount") as string;
    const price = parseFloat(priceString.length ? priceString : "0");
    formData.amount = (price * 100).toString();
  }

  try {
    await client.mutate({
      mutation: CREATE_DISCOUNT,
      variables: { data: { ...formData, last_updated_by: user?.id } },
    });
    toast.success("Discount created");
    return redirect("/discounts/list");
  } catch {
    toast.error("Failed to create discount");
  }
};

export const DiscountsCreate = () => {
  const [type, setType] = useState("percentage");
  const permissions = sessionStorage.getItem("hasuraDefaultRole");
  const organizations = useOrganizationStore((state) => state.organizations);
  const organizationId = useOrganizationStore((state) => state.organizationId);
  const [price, setPrice] = useState(0);

  const handlePriceChange = (value: number) => {
    setPrice(value);
  };

  return (
    <Form method="post" action="/discounts/create">
      <FormLayout>
        <div className="col-span-6">
          <Input label="Name" type="text" name="name" />
        </div>
        {permissions === "admin" && (
          <div className="col-span-6">
            <CustomSelect
              title="Organization"
              name="organization_id"
              required
              defaultValue={organizationId}
              options={organizations.map((org) => ({
                label: org.name,
                value: org.id,
              }))}
            />
          </div>
        )}
        {permissions !== "admin" && (
          <input
            type="hidden"
            value={organizationId ?? ""}
            name="organization_id"
          />
        )}
        {/*<div className="col-span-6">*/}
        {/*  <Input label="Discount Type" type="text" name="discount_type" />*/}
        {/*</div>*/}
        <div className="col-span-6">
          <Input label="Description" type="text" name="description" />
        </div>
        <div className="col-span-6">
          <Input label="Code" type="text" name="code" />
        </div>

        <div className="col-span-1">
          <RadioGroup
            label="Type"
            name="discount_type"
            defaultChecked={type}
            onChange={(value) => setType(value)}
            choices={[
              { title: "Percentage", id: "percentage" },
              { title: "Amount", id: "amount" },
            ]}
          />
        </div>
        <div className="col-span-6">
          <div className="w-full mr-4" role="group" aria-labelledby="">
            {type === "percentage" ? (
              <>
                <PercentageInput label="Percentage" name="percentage" />
                <input type="hidden" value={0} name="amount" />
              </>
            ) : (
              <>
                <PriceInput
                    label="Amount"
                    name="amount"
                    value={price}
                    onChange={(value) => handlePriceChange(value ? value : 0)}
                />
                <input type="hidden" value={0} name="percentage" />
              </>
            )}
          </div>
        </div>
      </FormLayout>
    </Form>
  );
};
