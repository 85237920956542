import { gql } from "@apollo/client";

export const GET_MENUS = gql`
  query GetMenus($where: menus_bool_exp!) {
    menus(where: $where, order_by: { name: asc }) {
      id
      name
      is_active
      updated_at
      created_at
      event_id
      event {
        name
        id
      }
      vendor_id
      vendor {
        name
        id
      }
      is_cash
      is_credit
      is_rfid
      is_tips
      is_qr
      is_tappit
      is_custom_item
      tip_percentage_1
      tip_percentage_2
      tip_percentage_3
      is_discount
      is_discount_protected
      is_cash_not_taxed
      is_cart_scanning
      processing_mode
      is_tab
      tax_type
      menu_items {
        item {
          name
        }
      }
      location_menus {
        location {
          id
          name
        }
      }
      user {
        username
      }

      __typename
    }
  }
`;

export const GET_MENU = gql`
  query GetMenu($id: Int!) {
    menus_by_pk(id: $id) {
      id
      name
      is_active
      event_id
      event {
        name
      }
      vendor_id
      vendor {
        name
      }
      menu_items {
        id
        menu_id
        item_id
      }
      location_menus {
        id
        location_id
        menu_id
      }
      is_cash
      is_credit
      is_rfid
      is_tips
      is_qr
      is_tappit
      is_custom_item
      tip_percentage_1
      tip_percentage_2
      tip_percentage_3
      is_discount
      is_discount_protected
      is_printing
      is_printing_auto
      is_printing_copies
      is_cash_not_taxed
      is_cart_scanning
      is_tab
      processing_mode
      category
      tax_type
      __typename
    }
  }
`;

export const GET_LOCATION_BY_MENU = gql`
  query GetLocationByMenu($id: Int!) {
    location_menu(where: { menu_id: { _eq: $id } }) {
      id
      location_id
      menu_id
      __typename
    }
  }
`;

export const GET_LOCATIONS = gql`
  query GetLocations($ids: [Int!]) {
    locations(where: { id: { _in: $ids } }) {
      id
      name
    }
  }
`;

export const GET_MENU_ITEMS = gql`
  query GetMenuItems($id: Int!) {
    menu_item(where: { menu_id: { _eq: $id } }) {
      id
      item_id
      menu_id
      __typename
    }
  }
`;

export const GET_ITEMS = gql`
  query GetItems($ids: [Int!]) {
    items(where: { id: { _in: $ids } }) {
      id
      name
      description
      price
      __typename
    }
  }
`;
