import { userStore } from "../../store/user";
import { organizationStore } from "../../store/organization";
import { eventStore } from "../../store/event";
import { usePermissions } from "../auth/api";
import { GET_ORDERS } from "./queries";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { usdFormat } from "../../utils";
import { TransactionReportRow } from "../../types/order";
import { Link, LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import OrderFilter from "./components/OrderFilter";
import { Badge } from "../../components/Badge";
import moment from "moment/moment";
import { parseInt } from "lodash";
import {
  ColDef,
  GridApi,
  SizeColumnsToContentStrategy,
} from "ag-grid-community";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { AgGridReact } from "ag-grid-react";

const client = createApolloClient();
const { getState } = userStore;
const { getState: getOrganizationState } = organizationStore;
const { getState: getEventState } = eventStore;

type CellRenderer = (params: {
  value:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  data: TransactionReportRow;
}) => JSX.Element;

const createCellRenderer = (
  permission: boolean,
  linkFunc: (params: { data: TransactionReportRow }) => string
): CellRenderer => {
  return (params) => (
    <>
      {permission ? (
        <Link to={linkFunc(params)}>
          <Badge>{params.value}</Badge>
        </Link>
      ) : (
        <Badge>{params.value}</Badge>
      )}
    </>
  );
};

export const ordersListLoader = async ({ request }: LoaderFunctionArgs) => {
  const rawOrganizationId = getOrganizationState().organizationId;
  const organizationId = parseInt(rawOrganizationId !== null ? rawOrganizationId.toString() : '', 10);
  const { eventId } = getEventState();
  const user = getState().user;
  const permissions = getState().permissions;
  const search = new URL(request.url);
  const where = search.searchParams.get("where");
  const vendorId = search.searchParams.get("vendor") ?? "";
  const username = search.searchParams.get("username") ?? "";
  const locationId = search.searchParams.get("location") ?? null;
  const last4ofCC = search.searchParams.get("last4ofCC") ?? "";
  const cardType = search.searchParams.get("cardType") ?? "";
  const transactionAmount = search.searchParams.get("transactionAmount") ?? "";
  const paymentRefId = search.searchParams.get("paymentRefId") ?? "";
  const email = search.searchParams.get("email") ?? "";
  const phoneNumber = search.searchParams.get("phoneNumber") ?? "";
  const mxRefId = search.searchParams.get("mxRefId") ?? "";
  const cashlessAssetId = search.searchParams.get("cashlessAssetId") ?? "";
  let whereVariable: any = where ? JSON.parse(where) : {};
  if (permissions !== "admin") {
    whereVariable = {
      ...whereVariable,
      payment_status: {
        _nin: ['pending', 'pending1'],
      },
    };
  }
  if (organizationId && organizationId !== 0) {
    whereVariable = {
      ...whereVariable,
      organization_id: {
        _eq: organizationId,
      },
    };
  }

  if (eventId) {
    whereVariable = {
      ...whereVariable,
      event_id: {
        _eq: eventId,
      },
    };
  } else if (user?.events) {
    whereVariable = {
      ...whereVariable,
      event_id: {
        _in: user?.events,
      },
    };
  }

  if (locationId !== null && locationId !== "") {
    whereVariable = {
      ...whereVariable,
      location_id: {
        _eq: parseInt(locationId, 10),
      },
    };
  }

  if (vendorId !== null && vendorId !== "") {
    whereVariable = {
      ...whereVariable,
      vendor_id: {
        _eq: parseInt(vendorId, 10),
      },
    };
  } else if (user?.vendors) {
    whereVariable = {
      ...whereVariable,
      vendor_id: {
        _in: user?.vendors,
      },
    };
  }
  if (username !== null && username !== "") {
    whereVariable = {
      ...whereVariable,
      username: {
        _ilike: username,
      },
    };
  }
  if (last4ofCC !== null && last4ofCC !== "") {
    whereVariable = {
      ...whereVariable,
      last4: {
        _eq: last4ofCC,
      },
    };
  }
  if (cardType !== null && cardType !== "") {
    whereVariable = {
      ...whereVariable,
      card_type: {
        _ilike: cardType,
      },
    };
  }
  if (transactionAmount !== null && transactionAmount !== "") {
    whereVariable = {
      ...whereVariable,
      transaction_amount: {
        _eq: parseInt(transactionAmount, 10),
      },
    };
  }
  if (paymentRefId !== null && paymentRefId !== "") {
    whereVariable = {
      ...whereVariable,
      payment_ref_id: {
        _ilike: "%" + paymentRefId + "%",
      },
    };
  }
  if (mxRefId !== null && mxRefId !== "") {
    whereVariable = {
      ...whereVariable,
      order_mx_ref_id: {
        _ilike: "%" + mxRefId + "%",
      },
    };
  }
  if (cashlessAssetId !== null && cashlessAssetId !== "") {
    whereVariable = {
      ...whereVariable,
      uid: {
        _ilike: "%" + cashlessAssetId + "%",
      },
    };
  } else {
    whereVariable = {
      ...whereVariable,
    };
  }
  const { data } = await client.query({
    query: GET_ORDERS,
    variables: { where: whereVariable },
  });
  return data.reports_transactions || [];
};

export const OrdersList = () => {
  const { ListPermission } = usePermissions("orders");
  const gridRef = useRef<GridApi>();
  const orders = useLoaderData() as TransactionReportRow[];
  const [rowData, setRowData] = useState<TransactionReportRow[]>([]);
  useEffect(() => {
    setRowData(orders);
  }, [orders]);
  const generateColDefs = (): ColDef<TransactionReportRow>[] => [
    {
      headerName: "Date",
      valueGetter: (p) => p.data?.transaction_time,
      pinned: "left",
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("ddd, MMM DD, YYYY h:mm a") : "";
      },
    },
    {
      headerName: "Date_Raw",
      valueGetter: (p) => p.data?.transaction_time,
      hide: true,
    },
    {
      headerName: "Username",
      valueGetter: (p) => p.data?.username,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Order ID",
      valueGetter: (p) => p.data?.order_id,
      cellRenderer: createCellRenderer(
        ListPermission,
        (p) => `/orders/${p.data.payment_ref_id}/${p.data?.order_id}`
      ),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Ronin Ref ID",
      valueGetter: (p) => p.data?.payment_ref_id,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Payment Processor Ref ID",
      valueGetter: (p) => p.data?.order_mx_ref_id,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Order Status",
      valueGetter: (p) => p.data?.order_status,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Payment Status",
      valueGetter: (p) => p.data?.payment_status,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Payment Type",
      valueGetter: (p) => p.data?.payment_type,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Cashless Asset ID",
      valueGetter: (p) => p.data?.uid,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Last 4 of CC",
      valueGetter: (p) => p.data?.last4,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Card Type",
      valueGetter: (p) => p.data?.card_type,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Transaction Amount",
      valueGetter: (p) => p.data?.transaction_amount,
      valueFormatter: (params) => usdFormat.format((params.value ?? 0) / 100),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Item Sub Total",
      valueGetter: (p) => p.data?.item_subtotal,
      valueFormatter: (params) => usdFormat.format((params.value ?? 0) / 100),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Tax",
      valueGetter: (p) => p.data?.tax,
      valueFormatter: (params) => usdFormat.format((params.value ?? 0) / 100),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Tip",
      valueGetter: (p) => p.data?.tip,
      valueFormatter: (params) => usdFormat.format((params.value ?? 0) / 100),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Service Fee",
      valueGetter: (p) => p.data?.digital_surcharge,
      valueFormatter: (params) => usdFormat.format((params.value ?? 0) / 100),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Applied Discount Name",
      valueGetter: (p) => p.data?.applied_discount_name,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Applied Discount Amount",
      valueGetter: (p) => p.data?.applied_discount_amount,
      valueFormatter: (params) => usdFormat.format((params.value ?? 0) / 100),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Organization Name",
      valueGetter: (p) => p.data?.organization_name,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Event Name",
      valueGetter: (p) => p.data?.event_name,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Vendor Name",
      valueGetter: (p) => p.data?.vendor_name,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Location Name",
      valueGetter: (p) => p.data?.location_name,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Order Items Count",
      valueGetter: (p) => p.data?.order_items_count,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Device Identifier",
      valueGetter: (p) => p.data?.device_app_id,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Build ID",
      valueGetter: (p) => p.data?.build_id,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Server Created Time",
      valueGetter: (p) => p.data?.created_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Order Item Details",
      valueGetter: (p) => JSON.stringify(p.data?.items_json),
      filter: "agTextColumnFilter",
    },
  ];
  const gridOptions = {
    enableCellTextSelection: true,
  };
  const colDefs: ColDef<TransactionReportRow>[] = generateColDefs();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  return (
    <div style={{ height: "75vh" }}>
      <div
        className={"ag-theme-quartz"}
        style={{ width: "100%", height: "100%" }}
      >
        <div className="mt-2 grid gap-2 grid-cols-6">
          <Breadcrumbs />
        </div>
        <div className="bg-white px-4 pb-3 mt-2 mb-2 pt-1 rounded-md">
          <OrderFilter />
        </div>
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          rowHeight={rowHeight}
          autoSizeStrategy={autoSizeStrategy}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={100}
          onGridReady={(params) => (gridRef.current = params.api)}
        />
      </div>
    </div>
  );
};
