import {
  ChangeEvent,
  Fragment,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import Papa from "papaparse";

import { Dialog, Transition } from "@headlessui/react";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import RadioGroup from "../inputs/RadioGroup";
import toast from "react-hot-toast";
import { userStore } from "../../store/user";
import { Categories } from "../../types/item";
import { CREATE_ITEM } from "../../modules/items/mutations";
import { VENDOR_CATAGORIES } from "../../modules/vendors/queries";
import { organizationStore } from "../../store/organization";
import { CREATE_VENDOR } from "../../modules/vendors/mutations";

interface VendorImportCSVModalProps {
  onConfirm: () => void;
  children: ({ setOpen }: { setOpen: any }) => ReactNode;
}

const client = createApolloClient();
const { getState: getUserState } = userStore;
const { getState: getOrganizationStore } = organizationStore;

export default function VendorImportCSVModal({
  onConfirm,
  children,
}: VendorImportCSVModalProps) {
  const user = getUserState().user;

  const [open, setOpen] = useState(false);
  const [fileType, setFileType] = useState("custom_file");
  const [fileName, setFileName] = useState("");
  const [allCategories, setAllCategories] = useState<Categories[]>([]);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const csvHeaderKeyValue = {
    Name: "name",
    "Vendor Type": "type",
    "Other Type": "other_type",
    "Point-of-Contact Name": "poc_name",
    "Point-of-Contact Email": "poc_email",
    "Point-of-Contact Phone Number": "poc_phone",
    "Revenue Share": "revenue_share",
    "Sales Tax Responsible": "sales_tax_responsible",
    "Flat Fee": "flat_fee",
  };

  const csvHeader = Object.keys(csvHeaderKeyValue)?.join(",");

  const transformData = (data: any, headerKeyValue: any) => {
    return data.map((item: any) => {
      const transformedItem: any = {};
      for (const key in item) {
        if (headerKeyValue[key]) {
          const convertValue =
            item[key] === "TRUE" || item[key] === true
              ? true
              : item[key] === "FALSE" || item[key] === false
              ? false
              : item[key];
          if (headerKeyValue[key] === csvHeaderKeyValue["Other Type"]) {
            if (item["Vendor Type"] === "other") {
              transformedItem[headerKeyValue[key]] = convertValue;
            } else {
              transformedItem[headerKeyValue[key]] = null;
            }
          } else {
            transformedItem[headerKeyValue[key]] = convertValue;
          }
        }
      }
      return transformedItem;
    });
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    console.log({ file });
    if (file) {
      setFileName(file.name);
      Papa.parse(file, {
        header: true, // If your CSV file has headers
        skipEmptyLines: true,
        complete: async (result) => {
          console.log({ resultData: result.data, result });

          const transformedDatas = transformData(
            result.data,
            csvHeaderKeyValue
          );
          console.log({ transformedDatas });
          for (let index = 0; index < transformedDatas.length; index++) {
            const transformedData = transformedDatas[index];
            setTimeout(() => {}, 1000);
            try {
              const { data } = await client.mutate({
                mutation: CREATE_VENDOR,
                variables: {
                  input: { ...transformedData, last_updated_by: user?.id },
                },
              });
              toast.success(
                `${transformedData?.name} vendor created successfully`
              );
            } catch (error) {
              toast.error("Error creating Vendor: " + error);
            }
          }
        },
        error: (error) => {
          console.error("Error reading CSV file:", error);
        },
      });
    } else {
      setFileName("");
    }
  };

  const handleDownloadSampleFile = () => {
    // const eventData = allEvents.find((event) => event.id === selectedEvent);
    // const vendorData = allVendors.find(
    //   (vendor) => vendor.id === selectedVendor
    // );
    // const taxTypeData = taxTypeOptions?.find(
    //   (taxType) => taxType.value === selectedCategory
    // );
    const csvContent = `${csvHeader}\n`;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "vendor.csv");
    link.click();
  };

  const cancelButtonRef = useRef(null);

  return (
    <>
      {children({ setOpen })}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full max-w-2xl max-h-[50vh] sm:my-8  sm:p-6">
                  <div className="sm:flex sm:items-start">
                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <InformationCircleIcon
                        className="h-6 w-6 text-yellow-600"
                        aria-hidden="true"
                      />
                    </div> */}
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h1"
                        className="text-2xl text-center font-medium leading-6 text-gray-900 mb-5"
                      >
                        Import CSV
                      </Dialog.Title>
                      <div className="mt-2">
                        {/* <p className="text-sm text-gray-500">
                          This action cannot be undone. Are you sure you want to
                          delete this?
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex">
                      <RadioGroup
                        label="File Type"
                        defaultChecked={fileType}
                        name="file_type"
                        onChange={(value) => {
                          setFileType(value);
                          setSelectedCategory(null);
                        }}
                        choices={[
                          // {
                          //   title: "Pre Fill File",
                          //   id: "pre_fill",
                          // },
                          {
                            title: "Custom File",
                            id: "custom_file",
                          },
                        ]}
                      />
                    </div>

                    <div className="mt-4">
                      <div className="flex justify-end">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={handleDownloadSampleFile}
                        >
                          Download Sample File
                        </button>
                      </div>
                      <label
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        htmlFor="file_input"
                      >
                        Upload CSV/Excel file{" "}
                      </label>

                      <input
                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        id="file_input"
                        type="file"
                        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={handleFileChange}
                      />
                      {/* {fileName && (
                        <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                          Selected file: {fileName}
                        </p>
                      )} */}
                    </div>
                  </div>
                  {/* <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleConfirm}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
