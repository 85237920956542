import moment from "moment";
import {AccessScansReportRow} from "./type";
import {ColDef} from "ag-grid-community";

const generateColDefs = (): ColDef<AccessScansReportRow>[] => [
  {
    headerName: 'Scan Time',
    valueGetter: p => p.data?.adjusted_scan_time,
    pinned: 'left',
    valueFormatter: p => {
      const date = p.value ? moment.utc(p.value) : null;
      return date ? date.format('ddd, MMM DD, YYYY h:mm a') : '';
    }
  },
  {
    headerName: 'Asset ID',
    valueGetter: p => p.data?.uid,
  },
  {
    headerName: 'Location Name',
    valueGetter: p => p.data?.location_name,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Scan Status',
    valueGetter: p => p.data?.scan_status,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Scan Message',
    valueGetter: p => p.data?.scan_status_message,
    filter: 'agTextColumnFilter',
  },
];

export const colDefs: ColDef<AccessScansReportRow>[] = generateColDefs();